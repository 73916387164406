import {
    SET_SECTION,
    SET_SECTION_NUMBER,
    SKIP_CORRECT,
    SET_STUDY,
    ADD_LP_ANSWER,
    REMOVE_LP_ANSWER,
    NEXT_LP_QUESTION,
    PREV_LP_QUESTION
} from "./types";

export const nextQuestion = () => {
    return {
        type: NEXT_LP_QUESTION,
    };
};

export const prevQuestion = () => {
    return {
        type: PREV_LP_QUESTION,
    };
};

export const setSection = (section: [], id: number) => {
    return {
        type: SET_SECTION,
        payload: {
            section,
            id
        }
    };
};

export const setSectionNumber = (id: number) => {
    return {
        type: SET_SECTION_NUMBER,
        payload: id
    };
}

export const setSkipCorrect = () => {
    return {
        type: SKIP_CORRECT,
    }
}

export const setStudy = () => {
    return {
        type: SET_STUDY,
    }
}

export const addLPAnswer = (answer: string, correct: string, dvsa: number | null) => {
    return {
        type: ADD_LP_ANSWER,
        payload: {
            answer,
            correct,
            dvsa,
        },
    };
};

export const removeLPAnswer = (answer: string, dvsa: number | null) => {
    return {
        type: REMOVE_LP_ANSWER,
        payload: {
            answer: answer,
            dvsa: dvsa
        }
    };
};