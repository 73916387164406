import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

interface FlagProps {
    videoClicks: number[];
    duration: number;
    ended: boolean | void;
}

export default function Flags({ videoClicks, duration, ended }: FlagProps) {
    return (
        <div id="flags" className="z-20">
            {videoClicks
                ? videoClicks.map((flag: number) => {
                      return (
                          <FontAwesomeIcon
                              icon={faFlag}
                              className="reviewflags"
                              key={flag}
                              style={{ marginLeft: `${(flag / duration) * 99}%` }}
                            /* {
                                   ended === true
                                       ? {
                                             marginLeft: `${
                                                 (flag / duration) * 99
                                             }%`,
                                         }
                                       : { display: "inline-block", margin: 0 }
                            } */
                          />
                      );
                  })
                : ""}
        </div>
    );
}
