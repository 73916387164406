import React, { Component, createRef } from "react";
import dashjs from "dashjs";

type VideoArray = {
    url: string;
    type: string;
};

type PropTypes = {
    url: string | VideoArray[];
    className?: string;
    playing?: boolean;
    loop?: boolean;
    controls?: boolean;
    playbackRate?: number;
    width?: string;
    height?: string;
    style?: object;
    progressInterval?: number;
    onReady?: Function;
    onStart?: Function;
    onPlay?: Function;
    onPause?: Function;
    onProgress?: Function;
    onDuration?: Function;
    onBuffer?: Function;
    onSeek?: Function;
    onEnded?: Function;
    onError?: Function;
    onClick?: Function;
};

type defaultProps = {
    playing: false;
    loop: false;
    controls: false;
    playbackRate: 1;
    width: "640px";
    height: "360px";
    style: {};
    progressInterval: 200;
    wrapper: "div";
    onReady: () => {};
    onStart: () => {};
    onPlay: () => {};
    onPause: () => {};
    onBuffer: () => {};
    onEnded: () => {};
    onError: () => {};
    onDuration: () => {};
    onSeek: () => {};
    onPlaybackRateChange: () => {};
    onProgress: () => {};
};

export default class Video extends Component<PropTypes, defaultProps> {
    player: React.RefObject<HTMLVideoElement>;
    dash;
    isReady: boolean = false;
    isPlaying: boolean = false; // Track playing state internally to prevent bugs
    isLoading: boolean = true; // Use isLoading to prevent onPause when switching URL
    seekOnPlay: number | null = null;
    progressTimeout: number = 0;
    prevPlayed: number = 0;
    prevLoaded: number = 0;

    isiOS = () => {
        return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    constructor(props: PropTypes) {
        super(props);
        this.player = createRef();
        this.dash = dashjs.MediaPlayer().create();
    }

    componentDidMount() {
        this.initializeVideo()
    }

    componentWillUnmount() {
        clearTimeout(this.progressTimeout);
        this.player.current!.srcObject = null;
    }

    componentDidUpdate(prevProps: any) {
        if (!this.player.current) {
            return null;
        }
        const { playing, playbackRate, loop, url } = this.props;
        if (!prevProps.playing && playing && !this.isPlaying) {
            this.player.current.play();
            this.isPlaying = true;
        }
        if (prevProps.playing && !playing && this.isPlaying) {
            this.player.current.pause();
            this.isPlaying = false;
        }
        if (prevProps.playbackRate !== playbackRate && playbackRate) {
            this.player.current.playbackRate = playbackRate;
        }
        if (prevProps.loop !== loop) {
            this.player.current.loop = loop!;
        }
        if (JSON.stringify(prevProps.url) !== JSON.stringify(url)) {
            if(typeof(this.dash) === 'object'){
                if(this.isiOS()) {
                    this.dash = dashjs.MediaPlayer().create()
                    this.initializeVideo()
                }
                else{
                    this.dash.reset()
                    this.dash.attachView(this.player.current)
                    this.dash.attachSource(
                        typeof this.props.url === "string"
                            ? this.props.url
                            : this.props.url[0].url
                    );
                }
            }
        }
    }

    addEventListeners(video: HTMLVideoElement) {
        const { onReady, onBuffer, onEnded, onDuration, onPlay, onError } = this.props;

        video.pause()
        
        video.oncanplaythrough = () => {
            this.isReady = true;
            onReady!();
        };
        video.onwaiting = () => {
            this.isReady = false;
            onBuffer!();
            var buffering = setTimeout(() => {
                if (this.player.current) {
                    if (this.player.current.readyState > 2) {
                        this.isReady = true;
                        onPlay!();
                        clearTimeout(buffering);
                    }
                }
            }, 500);
        };

        video.onended = () => onEnded!();
        video.onloadedmetadata = (event) => onDuration!(this.player.current!.duration);
        video.onerror = (event) => onError!();
    }

    initializeVideo() {
        if (this.player.current) {
            this.dash.initialize(
                this.player.current,
                typeof this.props.url === "string"
                    ? this.props.url
                    : this.props.url[0].url,
                false
            );
            if(this.isiOS() && this.props.url){
                this.player.current.src = typeof this.props.url === "string" ? this.props.url : this.props.url[1].url
            }
            this.addEventListeners(this.player.current);
            this.player.current.volume = 0;
            this.player.current.disablePictureInPicture = true;
            this.progress();
        }
    }

    getDuration() {
        if (!this.player.current) return null;
        return this.player.current.duration;
    }

    getCurrentTime() {
        if (!this.player.current) return null;
        return this.player.current.currentTime;
    }

    getSecondsLoaded() {
        if (!this.player.current) return null;
        const { buffered } = this.player.current;
        if (buffered.length === 0) {
            return 0;
        }
        const end = buffered.end(buffered.length - 1);
        const duration = this.getDuration()!;
        if (end > duration) {
            return duration;
        }
        return end;
    }

    progress = () => {
        if (this.props.url && this.player.current && this.isReady) {
            const playedSeconds = this.getCurrentTime() || 0;
            const loadedSeconds = this.getSecondsLoaded();
            const duration = this.getDuration();
            if (duration) {
                let progress = {
                    playedSeconds,
                    played: playedSeconds / duration,
                    loadedSeconds,
                    loaded: loadedSeconds ? loadedSeconds / duration : 0
                };
                if (
                    progress.playedSeconds !== this.prevPlayed ||
                    progress.loadedSeconds !== this.prevLoaded
                ) {
                    this.props.onProgress!(progress);
                }
                this.prevPlayed = progress.playedSeconds;
                this.prevLoaded = progress.loadedSeconds!;
            }
        }
        this.progressTimeout = setTimeout(
            this.progress,
            this.props.progressInterval
        );
    };

    seekTo(amount: number, type: string) {
        if (!this.isReady && amount !== 0) {
            this.seekOnPlay = amount;
            setTimeout(() => {
                this.seekOnPlay = null;
            }, 5000);
            return;
        }
        const isFraction = !type
            ? amount > 0 && amount < 1
            : type === "fraction";
        if (isFraction) {
            const duration = this.getDuration();
            if (!duration) {
                console.warn(
                    "Could not seek using fraction – duration not yet available"
                );
                return;
            }
            this.player.current!.currentTime = duration * amount;
            return;
        }
        this.player.current!.currentTime = amount;
    }

    render() {
        const {
            url,
            className,
            controls,
            loop,
            onClick,
            width,
            height,
            style
        } = this.props;
        return (
            <div className={className}>
                <video
                    ref={this.player}
                    controls={controls}
                    src={typeof url === "string" ? url : url[0].url}
                    loop={loop}
                    autoPlay={this.isiOS() ? true : false}
                    preload="auto"
                    controlsList="nodownload nofullscreen noremoteplayback"
                    muted
                    disablePictureInPicture
                    playsInline
                    width={width}
                    height={height}
                    style={style}
                    onClick={() => onClick!()}
                >
                    {url instanceof Array ? (
                        url.map((video) => {
                            return (
                                <source
                                    key={video.url}
                                    src={video.url}
                                    type={video.type}
                                />
                            );
                        })
                    ) : (
                        <source src={url} type="video/mp4" />
                    )}
                </video>
            </div>
        );
    }
}
