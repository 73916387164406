export default function Card(props: any) {
    const {children, ...rest} = props;
    return (
        <div className="bg-white rounded-lg shadow-md p-6 mb-3 clear-both" {...rest}>
            <span className="w-full block leading-normal text-gray-800 text-md">
                {children}
            </span>
        </div>
    )
}
