import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import Speech from "react-speech";

const styles = {
    container: {
        display: "block",
        float: "right",
        zIndex: 100000
    },
    text: {
        width: "100%",
        display: ""
    },
    play: {
        hover: {
            color: "#666"
        },
        button: {
            width: "34",
            height: "34",
            cursor: "pointer",
            pointerEvents: "none",
            float: "right"
        }
    },
    stop: {
        hover: {
            backgroundColor: "GhostWhite"
        },
        button: {
            width: "34",
            height: "34",
            cursor: "pointer",
            pointerEvents: "none",
            outline: "none",
            backgroundColor: "Gainsboro",
            border: "solid 1px rgba(255,255,255,1)",
            borderRadius: 6
        }
    },
    pause: {
        hover: {
            backgroundColor: "GhostWhite"
        },
        button: {
            width: "34",
            height: "34",
            cursor: "pointer",
            pointerEvents: "none",
            outline: "none",
            backgroundColor: "Gainsboro",
            border: "solid 1px rgba(255,255,255,1)",
            borderRadius: 6
        }
    },
    resume: {
        hover: {
            backgroundColor: "GhostWhite"
        },
        button: {
            width: "34",
            height: "34",
            cursor: "pointer",
            pointerEvents: "none",
            outline: "none",
            backgroundColor: "Gainsboro",
            border: "solid 1px rgba(255,255,255,1)",
            borderRadius: 6
        }
    }
};

export default function TextToSpeech(props: { text: any; voiceOver: boolean }) {
    if (props.voiceOver !== true) {
        return <></>;
    }
    const icon = <FontAwesomeIcon icon={faVolumeUp} size="lg" />;
    return (
        <span
            onClick={(e: any) => {
                e.stopPropagation();
            }}
        >
            <Speech
                text={props.text}
                displayText={icon}
                className="flex-none"
                textAsButton={true}
                voice='Samantha'
                styles={styles}
            />
        </span>
    );
}
