import { connect } from "react-redux";

import Button from "./Button";
import { TheoryFunctionProps, TheoryProps } from "../../types/theory";
import { faAngleLeft, faAngleRight, faFlag, faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
    nextQuestion,
    prevQuestion,
    flagQuestion,
    reviewAll,
    reviewFlagged,
    reviewIncomplete,
    reviewSummary,
    reviewAnswers,
    endTest
} from "../../actions";

function TestButtons(props: TheoryProps & TheoryFunctionProps & {className?: string, remaining?: number, fullScreenAvailable?: boolean}) {
    const { className, remaining, fullScreenAvailable,  testNo, numQuestions, currentQuestion, correct, incorrect, incomplete, overview, review, ended, flagged, prevQuestion, nextQuestion, flagQuestion, reviewSummary, reviewAll, reviewAnswers, reviewFlagged, reviewIncomplete, endTest } = props;
    if (overview !== false) {
        return (
            <div className={className}>
                <Button text={`Review ${ended !== true ? "All" : "Results"}`} fn={() => ended !== true ? reviewAll() : reviewAnswers()} className={fullScreenAvailable ? 'ml-2' : 'w-1/4 inline-block'} />
                {ended !== true ? 
                    <>
                        <Button text="Review Flagged" fn={() => flagged[testNo].length ? reviewFlagged() : null} disabled={flagged[testNo].length === 0 ? true : false} className={fullScreenAvailable ? '' : 'w-1/4 inline-block'} />
                        <Button text="Review Incomplete" fn={() => numQuestions[testNo] - (correct[testNo].length + incorrect[testNo].length) >= 1 ? reviewIncomplete() : null} disabled={numQuestions[testNo] - (correct[testNo].length + incorrect[testNo].length) === 0 ? true : false} className={fullScreenAvailable ? '' : 'w-1/4 inline-block'} />
                        <Button text="End Test" fn={() => endTest(remaining)} className={fullScreenAvailable ? 'mr-2' : 'w-1/4 inline-block'} />
                    </> : <>
                        <div className="m-2 mx-0"></div>
                        <div className="m-2 mx-0"></div>
                        <div className="m-2 mx-0"></div>
                    </>
                }
            </div>
        );
    }
    return (
        <div className={className}>
            {typeof review === 'boolean'
            || (review === 'flagged' && (flagged[testNo].length > 1 || currentQuestion !== flagged[testNo][0]))
            || (review === 'incomplete' && (incomplete[testNo].length > 1 || currentQuestion !== incomplete[testNo][0])) ? 
                    <Button text="Previous" fn={prevQuestion} icon={faAngleLeft} className={fullScreenAvailable ? 'ml-2' : 'w-1/4 inline-block'} />
                    : <div className={fullScreenAvailable ? 'm-2 mx-0' : 'w-1/4 inline-block'}></div>
            }
            {ended !== true ? 
                <Button text="Flag" fn={flagQuestion} icon={faFlag} iconClass={flagged[testNo].includes(currentQuestion) ? 'text-red-600' : ''} className={`${flagged[testNo].includes(currentQuestion) ? 'bg-blue-900' : ''} ${fullScreenAvailable ? '' : 'w-1/4 inline-block'}`} />
                : <div className={fullScreenAvailable ? 'm-2 mx-0' : 'w-1/4 inline-block'}></div>
            }
            <Button text="Review" fn={() => ended !== true ? reviewSummary() : endTest()} icon={faQuestion} className={fullScreenAvailable ? '' : 'w-1/4 inline-block'} />
            {typeof review === 'boolean'
                || (review === 'flagged' && (flagged[testNo].length > 1 || currentQuestion !== flagged[testNo][0]))
                || (review === 'incomplete' && (incomplete[testNo].length > 1 || currentQuestion !== incomplete[testNo][0])) ? 
                    <Button text="Next" fn={nextQuestion} icon={faAngleRight} className={fullScreenAvailable ? 'mr-2' : 'w-1/4 inline-block'} /> : 
                    <div className={fullScreenAvailable ? 'm-2 mx-0' : 'w-1/4 inline-block'}></div>
            }
        </div>
    );
}

const mapStateToProps = (state: any, myProps: {className?: string}): TheoryProps & TheoryFunctionProps => {
    return {
        ...state,
        testNo: state.question.testNo,
        currentQuestion: state.question.currentQuestion,
        overview: state.question.overview,
        ended: state.question.ended,
        numQuestions: state.question.numQuestions,
        correct: state.question.correct,
        incorrect: state.question.incorrect,
        incomplete: state.question.incomplete,
        flagged: state.question.flagged,
        review: state.question.review,
    };
};

const mapDispatchToProps = {
    nextQuestion,
    prevQuestion,
    flagQuestion,
    reviewAll,
    reviewFlagged,
    reviewIncomplete,
    reviewSummary,
    reviewAnswers,
    endTest,
}

export default connect<TheoryProps & TheoryFunctionProps>(mapStateToProps, mapDispatchToProps)(TestButtons);
