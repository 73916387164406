import { connect } from "react-redux";
import { TheoryProps } from '../../types/theory';

function TestOverview(props: TheoryProps) {
    return (
        <div className="lg:h-full p-3 bg-yellow-100">
            <div className="text-3xl font-semibold"><div className="float-right lg:mr-16 font-normal">{props.numQuestions[props.testNo]}</div> <div className="py-2 lg:p-6 lg:py-12"><span className="hidden lg:inline-block">Number of t</span><span className="lg:hidden">T</span>est questions: </div></div>
            <div className="text-3xl font-semibold"><div className="float-right lg:mr-16 font-normal">{props.correct[props.testNo].length + props.incorrect[props.testNo].length}</div><div className="py-2 lg:p-6 lg:py-12"><span className="hidden lg:inline-block">Number of c</span><span className="lg:hidden">C</span>ompleted questions: </div></div>
            <div className="text-3xl font-semibold"><div className="float-right lg:mr-16 font-normal">{props.numQuestions[props.testNo] - (props.correct[props.testNo].length + props.incorrect[props.testNo].length)}</div><div className="py-2 lg:p-6 lg:py-12"><span className="hidden lg:inline-block">Number of i</span><span className="lg:hidden">I</span>ncomplete questions: </div></div>
            <div className="text-3xl font-semibold"><div className="float-right lg:mr-16 font-normal">{props.flagged[props.testNo].length}</div><div className="py-2 lg:p-6 lg:py-12"><span className="hidden lg:inline-block">Number of f</span><span className="lg:hidden">F</span>lagged questions: </div></div>
        </div>
    )
}

const mapStateToProps = (state: any): TheoryProps => {
    return {
        ...state,
        testNo: state.question.testNo,
        correct: state.question.correct,
        incorrect: state.question.incorrect,
        numQuestions: state.question.numQuestions,
        flagged: state.question.flagged,
        dvsa: state.question.dvsa
    };
};

export default connect<TheoryProps, {}>(mapStateToProps, {})(TestOverview);