import {
    ADD_CLICK_TIME,
    NEXT_VIDEO,
    PREV_VIDEO,
    SET_VIDEOS,
    EXIT_HP_TEST,
    PLAY_VIDEO,
    PAUSE_VIDEO,
    PLAY_PAUSE_VIDEO,
    PLAYBACK_RATE,
    GO_TO_VIDEO,
    SET_DURATION,
    SET_ERROR,
    SCORE_PAUSE,
    SEEK_CHANGE,
    SET_PLAYED,
    SEEK_START,
    SEEK_END,
    BUFFERING,
    VIDEO_READY,
    VIDEO_ENDED,
    RESET_TEST,
    SET_HP_TEST,
    REVIEW_HP_TEST
} from "../actions/types";
import _ from "lodash";
import { RootStateOrAny } from "react-redux";

const INITIAL_STATE = {
    testNo: 0,
    currentVideo: 1,
    numVideos: [],
    videos: [],
    videoClicks: [],
    videoStatus: {
        url: "",
        playing: false,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        seeking: false,
        player: false,
        scoreWindow: 0,
        windowPause: false,
        error: false
    },
    tamper: [],
    scores: [],
    complete: [],
    passMark: 44,
    review: false,
    ended: false,
    maxClicks: 15
};

const clickScore = (seconds: number, max: number, min: number) => {
    if (seconds >= max && seconds < min) {
        return true;
    }
    return false;
};

const hazardPerceptionReducer = (
    state: RootStateOrAny = INITIAL_STATE,
    action: { type: string; payload: any }
) => {
    let videos = [...state.videos];
    let numVideos = [...state.numVideos];
    let videoClicks = [...state.videoClicks]
    let scores = [...state.scores];
    let tamper = [...state.tamper];
    let complete = [...state.complete];
    switch (action.type) {
        case SET_HP_TEST:
            videoClicks[action.payload] = [];
            tamper[action.payload] = [];
            scores[action.payload] = [];
            complete[action.payload] = null;
            return {
                ...state,
                testNo: action.payload,
                currentVideo: 1,
                review: false,
                ended: false,
                videoClicks,
                scores,
                tamper,
                complete
            };
        case SET_VIDEOS:
            videos[action.payload.testId] = action.payload.videos;
            numVideos[action.payload.testId] = action.payload.videos.length;
            scores[action.payload.testId] = [];
            tamper[action.payload.testId] = [];
            return {
                ...state,
                testNo: action.payload.testId,
                videos,
                numVideos,
                scores,
                tamper
            };
        case PLAY_PAUSE_VIDEO:
            return {
                ...state,
                videoStatus: {
                    ...state.videoStatus,
                    playing: !state.videoStatus.playing
                }
            };
        case PLAY_VIDEO:
            return {
                ...state,
                videoStatus: { ...state.videoStatus, playing: true }
            };
        case PAUSE_VIDEO:
            return {
                ...state,
                videoStatus: { ...state.videoStatus, playing: false }
            };
        case PLAYBACK_RATE:
            return {
                ...state,
                videoStatus: {
                    ...state.videoStatus,
                    playbackRate: action.payload
                }
            };
        case SET_DURATION:
            return {
                ...state,
                videoStatus: {
                    ...state.videoStatus,
                    playing: false,
                    duration: action.payload,
                    error: false
                }
            };
        case SET_ERROR:
            return {
                ...state,
                videoStatus: {
                    error: true
                }
            }
        case SCORE_PAUSE:
            return {
                ...state,
                videoStatus: {
                    ...state.videoStatus,
                    windowPause: !state.videoStatus.windowPause
                }
            };
        case VIDEO_READY:
            return {
                ...state,
                videoStatus: {
                    ...state.videoStatus,
                    loaded: 1
                }
            };
        case SET_PLAYED:
            let timePlayed = state.videoStatus.duration * action.payload;
            let currentScore = 0;
            let isPlaying = state.videoStatus.playing;
            let scoreWindows = state.videos[state.testNo];
            if (
                (timePlayed >= scoreWindows[state.currentVideo - 1].five &&
                    timePlayed < scoreWindows[state.currentVideo - 1].four) ||
                (timePlayed >= scoreWindows[state.currentVideo - 1].ten &&
                    timePlayed < scoreWindows[state.currentVideo - 1].nine)
            ) {
                currentScore = 5;
            } else if (
                (timePlayed >= scoreWindows[state.currentVideo - 1].four &&
                    timePlayed < scoreWindows[state.currentVideo - 1].three) ||
                (timePlayed >= scoreWindows[state.currentVideo - 1].nine &&
                    timePlayed < scoreWindows[state.currentVideo - 1].eight)
            ) {
                currentScore = 4;
            } else if (
                (timePlayed >= scoreWindows[state.currentVideo - 1].three &&
                    timePlayed < scoreWindows[state.currentVideo - 1].two) ||
                (timePlayed >= scoreWindows[state.currentVideo - 1].eight &&
                    timePlayed < scoreWindows[state.currentVideo - 1].seven)
            ) {
                currentScore = 3;
            } else if (
                (timePlayed >= scoreWindows[state.currentVideo - 1].two &&
                    timePlayed < scoreWindows[state.currentVideo - 1].one) ||
                (timePlayed >= scoreWindows[state.currentVideo - 1].seven &&
                    timePlayed < scoreWindows[state.currentVideo - 1].six)
            ) {
                currentScore = 2;
            } else if (
                (timePlayed >= scoreWindows[state.currentVideo - 1].one &&
                    timePlayed < scoreWindows[state.currentVideo - 1].endseq) ||
                (timePlayed >= scoreWindows[state.currentVideo - 1].six &&
                    timePlayed < scoreWindows[state.currentVideo - 1].endseq2)
            ) {
                currentScore = 1;
            } else {
                currentScore = 0;
            }
            isPlaying =
                state.videoStatus.windowPause &&
                state.videoStatus.scoreWindow !== currentScore
                    ? false
                    : state.videoStatus.playing;
            return {
                ...state,
                videoStatus: {
                    ...state.videoStatus,
                    played: action.payload,
                    scoreWindow: currentScore,
                    playing: isPlaying
                }
            };
        case SEEK_START:
            return {
                ...state,
                videoStatus: { ...state.videoStatus, seeking: true }
            };
        case SEEK_END:
            return {
                ...state,
                videoStatus: { ...state.videoStatus, seeking: false }
            };
        case SEEK_CHANGE:
            return {
                ...state,
                videoStatus: { ...state.videoStatus, played: action.payload }
            };
        case BUFFERING:
            return {
                ...state,
                currentVideo:
                    state.videoStatus.played >= 0.96
                        ? state.currentVideo === state.numVideos[state.testNo]
                            ? 1
                            : state.currentVideo + 1
                        : state.currentVideo,
                videoStatus:
                    state.videoStatus.played >= 0.96
                        ? {
                              ...state.videoStatus,
                              played: 0,
                              playing: false
                          }
                        : { ...state.videoStatus }
            };
        case VIDEO_ENDED:
            return state.currentVideo === state.numVideos[state.testNo]
                ? {
                      ...state,
                      ended: true,
                      review: true,
                      currentVideo: 1,
                      videoStatus: {
                          ...state.videoStatus,
                          played: 0,
                          loaded: 0,
                          playing: false
                      }
                  }
                : {
                      ...state,
                      currentVideo: state.currentVideo + 1,
                      videoStatus: {
                          ...state.videoStatus,
                          played: 0,
                          loaded: 0,
                          playing: false
                      }
                  };
        case NEXT_VIDEO:
            return state.currentVideo === state.numVideos[state.testNo]
                ? { ...state, ended: true, review: true }
                : { ...state, currentVideo: state.currentVideo + 1 };
        case PREV_VIDEO:
            return state.currentVideo > 1
                ? {
                      ...state,
                      currentVideo: state.currentVideo - 1,
                      videoStatus: {
                          ...state.videoStatus,
                          played: 0,
                          loaded: 0,
                          playing: false
                      }
                  }
                : {
                      ...state,
                      currentVideo: state.numVideos[state.testNo],
                      videoStatus: {
                          ...state.videoStatus,
                          played: 0,
                          loaded: 0,
                          playing: false
                      }
                  };
        case GO_TO_VIDEO:
            return {
                ...state,
                currentVideo: action.payload,
                review: false,
                videoStatus: {
                    ...state.videoStatus,
                    played: 0,
                    loaded: 0,
                    playing: false
                }
            };
        case ADD_CLICK_TIME:
            if (
                state.ended === false &&
                state.videoStatus.playing === true &&
                state.videoStatus.played > 0.001
            ) {
                let videoClicks: any[] = [...state.videoClicks];
                let currentVid: number = state.currentVideo;
                let videoStatus = state.videoStatus;
                scores[state.testNo] =
                    typeof scores[state.testNo] !== "undefined" &&
                    scores[state.testNo] !== null
                        ? scores[state.testNo]
                        : [];
                tamper[state.testNo] =
                    typeof tamper[state.testNo] !== "undefined" &&
                    tamper[state.testNo] !== null
                        ? tamper[state.testNo]
                        : [];
                videoClicks[state.testNo] =
                    typeof videoClicks[state.testNo] !== "undefined" &&
                    videoClicks[state.testNo] !== null
                        ? videoClicks[state.testNo]
                        : [];
                videoClicks[state.testNo][state.currentVideo - 1] = _.uniq(
                    _.concat(
                        _.without(
                            typeof state.videoClicks[state.testNo] !==
                                "undefined" &&
                                state.videoClicks[state.testNo] !== null
                                ? state.videoClicks[state.testNo][
                                      state.currentVideo - 1
                                  ]
                                : [],
                            null
                        ),
                        [action.payload.toFixed(2)]
                    )
                );
                const rapidClicking = videoClicks[state.testNo][state.currentVideo - 1].filter(
                    (second: number, index: number) =>
                        videoClicks[state.testNo][state.currentVideo - 1][
                            index + 4
                        ] -
                            second <= 1
                );
                const clickDifferences = _.map(
                    videoClicks[state.testNo][state.currentVideo - 1],
                    (e, i) =>
                        videoClicks[state.testNo][state.currentVideo - 1][
                            i + 1
                        ] - e
                );
                const repetitiveClicking = clickDifferences
                    .slice(1)
                    .map((n, i) => n - clickDifferences[i]);
                if (
                    rapidClicking.length >= 1 ||
                    videoClicks[state.testNo][state.currentVideo - 1].length >=
                        state.maxClicks ||
                    repetitiveClicking.filter(
                        (num) => num > -0.12 && num < 0.12
                    ).length >= 2
                ) {
                    tamper[state.testNo][state.currentVideo - 1] =
                        rapidClicking.length >= 1
                            ? "Rapid clicking"
                            : videoClicks[state.testNo][state.currentVideo - 1]
                                  .length >= state.maxClicks
                            ? "Too many clicks"
                            : "Click pattern";
                    scores[state.testNo][state.currentVideo - 1] = 0;
                    if (
                        state.videos[state.testNo][state.currentVideo - 1]
                            .nohazards > 1
                    ) {
                        scores[state.testNo][
                            state.videos[state.testNo].length + 1
                        ] = 0;
                    }
                    videoStatus = {
                        ...videoStatus,
                        played: 0,
                        loaded: 0,
                        playing: false
                    };
                    currentVid =
                        state.currentVideo < state.numVideos[state.testNo]
                            ? state.currentVideo + 1
                            : 1;
                }
                if (
                    !tamper[state.testNo][state.currentVideo - 1] &&
                    !scores[state.testNo][state.currentVideo - 1]
                ) {
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "five"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "four"
                            ]
                        )
                    ) {
                        scores[state.testNo][state.currentVideo - 1] = 5;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "four"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "three"
                            ]
                        )
                    ) {
                        scores[state.testNo][state.currentVideo - 1] = 4;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "three"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "two"
                            ]
                        )
                    ) {
                        scores[state.testNo][state.currentVideo - 1] = 3;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "two"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "one"
                            ]
                        )
                    ) {
                        scores[state.testNo][state.currentVideo - 1] = 2;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "one"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "endseq"
                            ]
                        )
                    ) {
                        scores[state.testNo][state.currentVideo - 1] = 1;
                    }
                }
                if (
                    !tamper[state.testNo][state.currentVideo - 1] &&
                    !scores[state.testNo][state.videos[state.testNo].length + 1]
                ) {
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "ten"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "nine"
                            ]
                        )
                    ) {
                        scores[state.testNo][
                            state.videos[state.testNo].length + 1
                        ] = 5;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "nine"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "eight"
                            ]
                        )
                    ) {
                        scores[state.testNo][
                            state.videos[state.testNo].length + 1
                        ] = 4;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "eight"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "seven"
                            ]
                        )
                    ) {
                        scores[state.testNo][
                            state.videos[state.testNo].length + 1
                        ] = 3;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "seven"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "six"
                            ]
                        )
                    ) {
                        scores[state.testNo][
                            state.videos[state.testNo].length + 1
                        ] = 2;
                    }
                    if (
                        clickScore(
                            action.payload,
                            state.videos[state.testNo][state.currentVideo - 1][
                                "six"
                            ],
                            state.videos[state.testNo][state.currentVideo - 1][
                                "endseq2"
                            ]
                        )
                    ) {
                        scores[state.testNo][
                            state.videos[state.testNo].length + 1
                        ] = 1;
                    }
                }
                return {
                    ...state,
                    videoClicks,
                    scores,
                    tamper,
                    currentVideo: currentVid,
                    videoStatus
                };
            }
            return { ...state };
        case EXIT_HP_TEST:
            const totalScore = state.scores[state.testNo].length
                ? state.scores[state.testNo].reduce(
                      (a: number, b: number) => a + b
                  )
                : 0;
            complete[state.testNo] = {
                totalScore: totalScore,
                status: totalScore >= state.passMark ? 1 : 0
            };
            return {
                ...state,
                ended: true,
                review: true,
                complete
            };
        case REVIEW_HP_TEST:
            return {
                ...state,
                testNo: action.payload,
                ended: true,
                review: true
            };
        case RESET_TEST:
            videoClicks[state.testNo] = [];
            tamper[state.testNo] = [];
            scores[state.testNo] = [];
            complete[state.testNo] = null;
            return {
                ...state,
                videoClicks,
                tamper,
                scores,
                complete
            };
        default:
            return state;
    }
};

export default hazardPerceptionReducer;
