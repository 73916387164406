export default function AlertBanner({review}: {review: string | boolean}) {
    if (typeof review === "string") {
        return (
            <div className="border border-yellow-300 bg-yellow-200 text-center animate-pulse my-2 p-2 font-bold">
                Reviewing {review} questions only
            </div>
        );
    }
    return <></>;
}
