import {
    NEXT_QUESTION,
    PREV_QUESTION,
    FLAG_QUESTION,
    TOGGLE_SOUND,
    GO_TO_QUESTION,
    REMOVE_ANSWER,
    ADD_ANSWER,
    REVIEW_ALL,
    REVIEW_FLAGGED,
    REVIEW_INCOMPLETE,
    REVIEW_SUMMARY,
    REVIEW_ANSWERS,
    REVIEW_TEST,
    END_TEST,
    SET_QUESTIONS,
    SET_TEST_NO
} from "./types";

export const setTestNo = (testNo: number) => {
    return {
        type: SET_TEST_NO,
        payload: testNo
    }
}

export const setQuestions = (questions: [], testNo: number) => {
    return {
        type: SET_QUESTIONS,
        payload: {
            questions,
            testNo
        }
    };
};

export const nextQuestion = () => {
    return {
        type: NEXT_QUESTION,
    };
};

export const prevQuestion = () => {
    return {
        type: PREV_QUESTION,
    };
};

export const goToQuestion = (questionNo: number) => {
    return {
        type: GO_TO_QUESTION,
        payload: questionNo,
    };
};

export const flagQuestion = () => {
    return {
        type: FLAG_QUESTION,
    };
};

export const toggleVoiceover = () => {
    return {
        type: TOGGLE_SOUND,
    };
};

export const addAnswer = (answer: string, correct: string, dvsa: number | null) => {
    return {
        type: ADD_ANSWER,
        payload: {
            answer: answer,
            correct: correct,
            dvsa: dvsa,
        },
    };
};

export const removeAnswer = (answer: string, dvsa: number| null) => {
    return {
        type: REMOVE_ANSWER,
        payload: {
            answer: answer,
            dvsa: dvsa
        }
    };
};

export const reviewAll = () => {
    return {
        type: REVIEW_ALL,
    };
};

export const reviewFlagged = () => {
    return {
        type: REVIEW_FLAGGED,
    };
};

export const reviewSummary = () => {
    return {
        type: REVIEW_SUMMARY,
    };
};

export const reviewIncomplete = () => {
    return {
        type: REVIEW_INCOMPLETE,
    };
};

export const endTest = (secondsRemaining?: number) => {
    return {
        type: END_TEST,
        payload: secondsRemaining
    };
};

export const reviewAnswers = () => {
    return {
        type: REVIEW_ANSWERS,
    };
};

export const reviewTest = (testNo: number) => {
    return {
        type: REVIEW_TEST,
        payload: testNo
    }
}