export default function MarkNumAnswers(props: {mark: number}) {
    const numbers: {[key: number]: string} = {
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
        5: 'five',
        6: 'six'
    }
    return (
        <div className="font-bold text-red-600 italic">
            Mark {numbers[props.mark]} answer
            {props.mark > 1
                ? "s"
                : ""}
        </div>
    )
}
