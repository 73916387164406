import Card from "./Card"
import Input from "./Input"
import Button from "./Button"
import { ChangeEvent, FormEvent, useState } from "react";

export default function Search({searchEvent}: {searchEvent?: Function}) {
    const [search, setSearch] = useState('');

    const submitHandler = (e: FormEvent) => {
        e.preventDefault()
        if(searchEvent) {searchEvent(search)}
    }

    return (
        <Card>
            <form onSubmit={submitHandler}>
            <Input type="text" className="w-auto inline-block rounded-r-none" placeholder="Search..." onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)} value={search} />
            <Button type="submit" className="inline-block bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 rounded-l-none">Search</Button>
            </form>
        </Card>
    )
}
