import { useEffect } from "react";
import { connect } from "react-redux";
import { TheoryProps, TheoryFunctionProps } from '../../types/theory';
import { setTestNo, reviewTest } from "../../actions/";
import TestReview from "../TestReview";
import TheoryTest from './TheoryTest';

function TheoryTestOverview(props: TheoryProps & TheoryFunctionProps) {
    const { testNo, complete, setTestNo, reviewTest } = props;

    useEffect(() => {

    }, [])

    if (testNo >= 1) {
        return <TheoryTest testNo={testNo} />
    } else {
        return <TestReview noTests={14} score={50} testName="Theory" takeTest={setTestNo} reviewTest={reviewTest} userData={complete} />
    }
}

const mapStateToProps = (state: any): TheoryProps & TheoryFunctionProps => {
    return {
        ...state,
        testNo: state.question.testNo,
        complete: state.question.complete
    };
};

const mapDispatchToProps = { setTestNo, reviewTest }

export default connect<TheoryProps & TheoryFunctionProps>(mapStateToProps, mapDispatchToProps)(TheoryTestOverview);
