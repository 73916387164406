import { Oval } from "react-loader-spinner"

export default function Spinner() {
    const width = 80;
    const height = 80;
    return (
        <div style={{ width, height }} className="mx-auto">
            <Oval color="#00BFFF" width={width} height={height} />
        </div>
    )
}
