import TextToSpeech from '../TheoryTest/TextToSpeech';

export default function Study(props: {explanation: string | null, visible: boolean, voiceOver: boolean}) {
    if (props.explanation){
        return (
            <div className={`${props.visible ? `h-full p-3 mt-2 border-4` : `hidden`} border-yellow-500 bg-yellow-100`}>
                <TextToSpeech text={props.explanation} voiceOver={props.voiceOver}  />  
                <span className="font-extrabold">DVSA Explanation:</span><br />
                {props.explanation}
            </div>
        )
    }
    return null;
}
