import { useRef } from "react";
import { connect } from "react-redux";
import Video from "./Video";
import { Hazard } from "./hazard";
import {
    playVideo,
    pauseVideo,
    setDuration,
    setPlayed,
    addVideoClick,
    setBuffering,
    setError,
    setVideoReady,
    setVideoEnded
} from "../../actions/hazard";
import Overlay from "./Overlay";

function VideoPlayer(props: Hazard) {
    const player: any = useRef();

    const {
        testNo,
        videos,
        videoStatus,
        currentVideo,
        playVideo,
        pauseVideo,
        setDuration,
        setPlayed,
        addVideoClick,
        setBuffering,
        setError,
        setVideoReady,
        setVideoEnded
    }: Hazard = props;

    if (videoStatus.seeking === true) {
        player.current.seekTo(videoStatus.played, "fraction");
    }

    const handleClick = (e: any) => {
        addVideoClick(player.current.getCurrentTime());
    };

    return (
        <div className="player-wrapper max-h-[85vh] relative md:overflow-hidden bg-black">
            <Video
                className={`${
                    videos[testNo][currentVideo - 1].ratio === "4by3"
                        ? "aspect-w-4 aspect-h-3"
                        : "aspect-w-16 aspect-h-9"
                } max-h-[85vh] mx-auto`}
                ref={player}
                url={[
                    {
                        url: videos[testNo][currentVideo - 1].src,
                        type: "application/dash+xml"
                    },
                    {
                        url: videos[testNo][currentVideo - 1].src
                            .replace("dash", "mp4")
                            .replace("mpd", "mp4"),
                        type: "video/mp4"
                    },
                    {
                        url: videos[testNo][currentVideo - 1].src
                            .replace("dash", "ogv")
                            .replace("mpd", "ogv"),
                        type: "video/ogg"
                    }
                ]}
                playing={videoStatus.playing}
                controls={false}
                playbackRate={videoStatus.playbackRate}
                progressInterval={200}
                width="auto"
                height="auto"
                style={{ width: "auto", height: "100%", maxHeight: "100vh" }}
                onReady={setVideoReady}
                onPlay={playVideo}
                onPause={pauseVideo}
                onBuffer={setBuffering}
                onEnded={setVideoEnded}
                onProgress={(e: any) => setPlayed(e)}
                onDuration={setDuration}
                onError={setError}
                onClick={handleClick}
            />
            <Overlay />
        </div>
    );
}

const mapStateToProps = (state: any): Hazard => {
    return {
        ...state,
        testNo: state.hazard.testNo,
        videos: state.hazard.videos,
        videoClicks: state.hazard.videoClicks,
        currentVideo: state.hazard.currentVideo,
        videoStatus: state.hazard.videoStatus
    };
};

const mapDispatchToProps = {
    playVideo,
    pauseVideo,
    setDuration,
    setPlayed,
    addVideoClick,
    setBuffering,
    setError,
    setVideoReady,
    setVideoEnded
};

export default connect<Hazard, {}>(
    mapStateToProps,
    mapDispatchToProps
)(VideoPlayer);
