import _ from "lodash";
import {
    SET_QUESTIONS,
    NEXT_QUESTION,
    PREV_QUESTION,
    FLAG_QUESTION,
    TOGGLE_SOUND,
    GO_TO_QUESTION,
    REVIEW_ALL,
    REVIEW_FLAGGED,
    REVIEW_INCOMPLETE,
    REVIEW_SUMMARY,
    REVIEW_ANSWERS,
    REVIEW_TEST,
    END_TEST,
    ADD_ANSWER,
    REMOVE_ANSWER,
    SET_TEST_NO
} from "../actions/types";

type stateTypes = {
    testNo: number;
    currentQuestion: number;
    numQuestions: number[];
    voiceOver: boolean;
    review: boolean | string;
    overview: boolean;
    ended: boolean;
    passMark: number;
    complete: any[];
    correct: number[][];
    incorrect: number[][];
    incomplete: number[][];
    answers: any[][];
    flagged: number[][];
    dvsa: any[][];
    section?: number;
    questions: any[][];
};

const INITIAL_STATE = {
    testNo: 0,
    currentQuestion: 1,
    numQuestions: [],
    passMark: 43,
    voiceOver: false,
    review: false,
    overview: false,
    ended: false,
    complete: [],
    correct: [],
    incorrect: [],
    incomplete: [],
    answers: [],
    flagged: [],
    dvsa: [],
    questions: [],
};

const theoryTestReducer = (
    state: stateTypes = INITIAL_STATE,
    action: { type: string; payload: any }
) => {
    let questions = [...state.questions];
    let answers = [...state.answers];
    let flagged = [...state.flagged];
    let numQuestions = [...state.numQuestions];
    let incomplete = [...state.incomplete];
    let correct = [...state.correct];
    let incorrect = [...state.incorrect];
    let dvsa = [...state.dvsa];
    let complete = [...state.complete];
    switch (action.type) {
        case SET_TEST_NO:
            answers[action.payload] = [];
            flagged[action.payload] = [];
            correct[action.payload] = [];
            incorrect[action.payload] = [];
            dvsa[action.payload] = [];
            incomplete[action.payload] = [];
            complete[action.payload] = [];
            if (questions[action.payload]) {
                incomplete[action.payload] = Array.from(
                    questions[action.payload],
                    (value, index) => index + 1
                );
                questions[action.payload].map((question: any) => {
                    dvsa[action.payload][question.dsacat] = {numQuestions: dvsa[action.payload][question.dsacat] ? dvsa[action.payload][question.dsacat]['numQuestions'] + 1 : 1, correct: [], incorrect: []};
                    return null;
                });
            }
            return {
                ...state,
                currentQuestion: 1,
                testNo: action.payload,
                answers,
                flagged,
                correct,
                incorrect,
                dvsa,
                review: false,
                overview: false,
                ended: false
            };
        case SET_QUESTIONS:
            questions[action.payload.testNo] = action.payload.questions;
            numQuestions[action.payload.testNo] = action.payload.questions.length;
            answers[action.payload.testNo] = answers[action.payload.testNo] ? answers[action.payload.testNo] : Array.from(
                action.payload.questions,
                (value, index) => index + 1
            );
            incomplete[action.payload.testNo] = incomplete[action.payload.testNo] ? incomplete[action.payload.testNo] : Array.from(
                action.payload.questions,
                (value, index) => index + 1
            );
            flagged[action.payload.testNo] = flagged[action.payload.testNo] ? flagged[action.payload.testNo] : [];
            correct[action.payload.testNo] = correct[action.payload.testNo] ? correct[action.payload.testNo] : [];
            incorrect[action.payload.testNo] = incorrect[action.payload.testNo] ? incorrect[action.payload.testNo] : [];
            dvsa[action.payload.testNo] = dvsa[action.payload.testNo] ? dvsa[action.payload.testNo] : [];
            if(dvsa[action.payload.testNo].length === 0) {
                action.payload.questions.map((question: { dsacat: number; }) => {
                    dvsa[action.payload.testNo][question.dsacat] = {numQuestions: dvsa[action.payload.testNo][question.dsacat] ? dvsa[action.payload.testNo][question.dsacat]['numQuestions'] + 1 : 1, correct: [], incorrect: []};
                    return null;
                });
            }
            
            return {
                ...state,
                testNo: action.payload.testNo,
                questions,
                answers,
                numQuestions,
                flagged,
                incomplete,
                correct,
                incorrect,
                dvsa
            };
        case NEXT_QUESTION:
            if (state.review === "flagged") {
                let flaggedIndex = _.indexOf(
                    state.flagged[state.testNo],
                    state.currentQuestion
                );
                if (flaggedIndex < 0) {
                    flaggedIndex = _.findIndex(
                        state.flagged[state.testNo],
                        (flagged) => flagged <= state.currentQuestion
                    );
                }
                return flaggedIndex < state.flagged[state.testNo].length - 1
                    ? {
                          ...state,
                          currentQuestion:
                              state.flagged[state.testNo][flaggedIndex + 1],
                      }
                    : {
                          ...state,
                          currentQuestion: state.flagged[state.testNo][0],
                      };
            }
            if (state.review === "incomplete") {
                let incompleteIndex = _.indexOf(
                    state.incomplete[state.testNo],
                    state.currentQuestion
                );
                if (incompleteIndex < 0) {
                    incompleteIndex = _.findIndex(
                        state.incomplete[state.testNo],
                        (incomplete) => incomplete <= state.currentQuestion
                    );
                }
                return incompleteIndex <
                    state.incomplete[state.testNo].length - 1
                    ? {
                          ...state,
                          currentQuestion:
                              state.incomplete[state.testNo][
                                  incompleteIndex + 1
                              ],
                      }
                    : {
                          ...state,
                          currentQuestion: state.incomplete[state.testNo][0],
                      };
            }
            return state.currentQuestion < state.numQuestions[state.testNo]
                ? { ...state, currentQuestion: state.currentQuestion + 1 }
                : { ...state, currentQuestion: 1 };
        case PREV_QUESTION:
            if (state.review === "flagged") {
                let flaggedIndex = _.indexOf(
                    state.flagged[state.testNo],
                    state.currentQuestion
                );
                if (flaggedIndex < 0) {
                    flaggedIndex = _.findIndex(
                        state.flagged[state.testNo],
                        (flagged) => flagged >= state.currentQuestion
                    );
                }
                return flaggedIndex > 0
                    ? {
                          ...state,
                          currentQuestion:
                              state.flagged[state.testNo][flaggedIndex - 1],
                      }
                    : {
                          ...state,
                          currentQuestion:
                              state.flagged[state.testNo][
                                  state.flagged.length - 1
                              ],
                      };
            }
            if (state.review === "incomplete") {
                let incompleteIndex = _.indexOf(
                    state.incomplete[state.testNo],
                    state.currentQuestion
                );
                if (incompleteIndex < 0) {
                    incompleteIndex = _.findIndex(
                        state.incomplete[state.testNo],
                        (incomplete) => incomplete <= state.currentQuestion
                    );
                }
                return incompleteIndex > 0
                    ? {
                          ...state,
                          currentQuestion:
                              state.incomplete[state.testNo][
                                  incompleteIndex - 1
                              ],
                      }
                    : {
                          ...state,
                          currentQuestion:
                              state.incomplete[state.testNo][
                                  state.incomplete.length - 1
                              ],
                      };
            }
            return state.currentQuestion > 1
                ? { ...state, currentQuestion: state.currentQuestion - 1 }
                : { ...state, currentQuestion: state.numQuestions[state.testNo] };
        case GO_TO_QUESTION:
            return { ...state, currentQuestion: action.payload };
        case FLAG_QUESTION:
            let currentFlagged = [...state.flagged];
            currentFlagged[state.testNo] = currentFlagged[
                state.testNo
            ].includes(state.currentQuestion)
                ? _.pull(state.flagged[state.testNo], state.currentQuestion)
                : _.concat(state.flagged[state.testNo], state.currentQuestion);
            state.flagged[state.testNo] = _.uniq(state.flagged[state.testNo]);
            return {
                ...state,
                flagged: currentFlagged,
                review: currentFlagged[state.testNo].length >= 1 ? state.review : false,
            };
        case TOGGLE_SOUND:
            return { ...state, voiceOver: !state.voiceOver };
        case ADD_ANSWER:
            answers[state.testNo][state.currentQuestion - 1] =
                action.payload.answer;
            if (!Array.isArray(state.dvsa[action.payload.dvsa])) {
                dvsa[action.payload.dvsa] = [];
            }
            if (
                action.payload.correct ===
                answers[state.testNo][state.currentQuestion - 1]
            ) {
                correct[state.testNo].push(state.currentQuestion);
                dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['correct'].push(state.currentQuestion);
                _.pull(incorrect[state.testNo], state.currentQuestion);
                _.pull(dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['incorrect'], state.currentQuestion);
            } else {
                incorrect[state.testNo].push(state.currentQuestion);
                dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['incorrect'].push(state.currentQuestion);
                _.pull(correct[state.testNo], state.currentQuestion);
                _.pull(dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['correct'], state.currentQuestion);
            }
            _.pull(incomplete[state.testNo], state.currentQuestion);
            correct[state.testNo] = _.uniq(correct[state.testNo]);
            incorrect[state.testNo] = _.uniq(incorrect[state.testNo]);
            dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['correct'] = _.uniq(dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['correct']);
            dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['incorrect'] = _.uniq(dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['incorrect']);
            return {
                ...state,
                answers,
                correct,
                incorrect,
                incomplete,
                dvsa
            };
        case REMOVE_ANSWER:
            answers[state.testNo][state.currentQuestion - 1] =
            answers[state.testNo][state.currentQuestion - 1].replace(
                    action.payload.answer,
                    ""
                );
            _.pull(incorrect[state.testNo], state.currentQuestion);
            _.pull(correct[state.testNo], state.currentQuestion);
            _.pull(dvsa[action.payload.dvsa], state.currentQuestion);
            _.pull(dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['correct'], state.currentQuestion);
            _.pull(dvsa[state.testNo][state.questions[state.testNo][state.currentQuestion - 1].dsacat]['incorrect'], state.currentQuestion);
            return {
                ...state,
                answers,
                correct,
                incorrect,
                incomplete,
                dvsa
            };
        case REVIEW_SUMMARY:
            return { ...state, overview: "summary", review: false };
        case REVIEW_ALL:
            return {
                ...state,
                overview: false,
                currentQuestion: 1,
                review: false,
            };
        case REVIEW_FLAGGED:
            return {
                ...state,
                overview: false,
                currentQuestion: state.flagged[state.testNo][0],
                review: "flagged",
            };
        case REVIEW_INCOMPLETE:
            return {
                ...state,
                overview: false,
                review: "incomplete",
                currentQuestion: state.incomplete[state.testNo][0],
            };
        case REVIEW_ANSWERS:
            return { ...state, overview: false, currentQuestion: 1 };
        case REVIEW_TEST:
            return { ...state, testNo: action.payload, overview: "results", review: true, ended: true }
        case END_TEST:
            if(action.payload) {
                complete[state.testNo] = {
                    taken: action.payload,
                    date: new Date().toLocaleDateString('en-GB'),
                    totalScore: state.correct[state.testNo].length,
                    status: state.correct[state.testNo].length >= state.passMark ? 1 : 0
                }
            }
            return { ...state, overview: "results", review: true, ended: true, complete };
        default:
            return state;
    }
};

export default theoryTestReducer;
