import { connect } from "react-redux";
import { Hazard } from "./hazard";
import { setSeekChange, setSeekStart, setSeekEnd } from "../../actions/hazard";

function VideoProgress({
    videoStatus,
    setSeekStart,
    setSeekChange,
    setSeekEnd,
}: Hazard) {
    return (
        <input
            type="range"
            min={0}
            max={1}
            step="any"
            value={videoStatus.played}
            onMouseDown={setSeekStart}
            onChange={(e) => setSeekChange(e)}
            onMouseUp={setSeekEnd}
            className="block w-full px-1"
        />
    );
}

const mapStateToProps = (state: any): Hazard => {
    return {
        ...state,
        videoStatus: state.hazard.videoStatus,
    };
};

const mapDispatchToProps = {
    setSeekChange,
    setSeekStart,
    setSeekEnd
};

export default connect<Hazard>(mapStateToProps, mapDispatchToProps)(VideoProgress);
