import { useState } from "react";
import { connect } from "react-redux";
import { AxiosResponse } from "axios";
import { setSectionNumber } from "../../actions/learning";
import theory from "../../api/myldc";
import LearningProgram from "./TheoryLearning";
import { LPProps, SectionType } from "../../types/learning";
import Spinner from "../Spinner";

interface PassedProps {
    list: string
}

function SectionList(props: LPProps & PassedProps) {
    const { list, correct, incorrect, currentSection, setSectionNumber } = props;
    const [sections, setSections] = useState<SectionType[] | []>(() => {
        const savedData = localStorage.getItem(`${list}_sections`);
        const values = savedData ? JSON.parse(savedData) : null;
        return values || [];
    });

    if (sections.length === 0) {
        theory
            .get(`learning/list?table=${list}`)
            .then((response: AxiosResponse<any>) => {
                localStorage.setItem(`${list}_sections`, JSON.stringify(response.data));
                setSections(response.data);
            });
        return <Spinner />;
    }

    if (currentSection >= 1) {
        return <LearningProgram section={currentSection} />;
    }

    const sectionList = sections.map((section) => {
        const sectionCorrect = correct[section.section] ? correct[section.section].length : 0;
        const sectionIncorrect = incorrect[section.section] ? incorrect[section.section].length : 0;

        return (
            <tr
                key={section.section}
                className={`p-1.5 border-b ${section.num_questions > 0 ? 'hover:bg-blue-500 hover:text-white cursor-pointer' : ''}`}
                onClick={() => section.num_questions > 0 ? setSectionNumber(section.section) : null}
            >
                <td className="p-1.5">{section.name}</td>
                <td className="text-center p-1.5">{section.num_questions}</td>
                <td className="text-center p-1.5">{sectionCorrect}</td>
                <td className="text-center p-1.5">{sectionIncorrect}</td>
                <td className="text-center p-1.5 hidden sm:table-cell">{section.num_questions - (sectionCorrect + sectionIncorrect)}</td>
            </tr>
        );
    });

    return <table className="w-full border select-none bg-white rounded-lg shadow-md">
        <thead>
            <tr className="font-bold bg-gray-300 rounded-t-lg">
                <th className="p-1.5 rounded-tl-lg">Section</th>
                <th className="text-center p-1.5">Questions</th>
                <th className="text-center p-1.5">Correct</th>
                <th className="text-center p-1.5">Incorrect</th>
                <th className="text-center p-1.5 hidden sm:table-cell">Incomplete</th>
            </tr>
        </thead>
        <tbody>{sectionList}</tbody>
    </table>;
}

const mapStateToProps = (state: any) => ({
    ...state,
    currentSection: state.learning.currentSection,
    correct: state.learning.correct,
    incorrect: state.learning.incorrect
});

const mapDispatchToProps = { setSectionNumber };

export default connect<LPProps, {}, PassedProps>(mapStateToProps, mapDispatchToProps)(SectionList);
