interface TestReviewProps {
    noTests: number,
    score: number,
    takeTest: Function,
    reviewTest: Function,
    userData: any[],
    testName?: string
}

const TestReview = (props: TestReviewProps) => {
    const { noTests, score, testName, takeTest, reviewTest, userData} = props;

    const createTestRow = (rows: number) => {
        let tableRows = [];
        for (let i = 0; i < rows; i++){
            
            tableRows.push(<tr key={i} className={`text-black hover:bg-gray-100 border border-l-0 border-r-0`}>
                <td><button className="p-1.5 block w-full text-left font-semibold hover:font-semibold text-blue-500 hover:text-red-800" onClick={() => takeTest(i+1)}>{testName ? testName+" " : ""} Test {i+1}</button></td>
                <td className="text-center p-1.5 font-bold">{userData[i+1] ? userData[i+1].totalScore : 0} / {score}</td>
                <td className={`text-center p-1.5 font-bold ${userData[i+1] ? userData[i+1].status === 1 ? 'text-green-600' : userData[i+1].status === 2 ? 'text-yellow-600' : 'text-red-600' : null}`}>{userData[i+1] ? userData[i+1].status === 1 ? 'Passed' : userData[i+1].status === 2 ? 'Incomplete' : 'Failed' : null}</td>
                <td className="text-center">{userData[i+1] ? <button className="p-1.5 block w-full font-semibold hover:font-semibold text-blue-500 hover:text-red-800" onClick={() => reviewTest(i+1)}>Review</button> : null}</td>
            </tr>)
        }
        return tableRows;
    }
    
    return (
        <table className="table-auto w-full border bg-white rounded-lg shadow-md">
            <thead>
                <tr className="font-bold bg-gray-300">
                    <td className="p-1.5"></td>
                    <td className="text-center p-1.5">Mark</td>
                    <td className="text-center p-1.5">Status</td>
                    <td className="text-center p-1.5">Review</td>
                </tr>
            </thead>
            <tbody>
                {noTests >= 1 ? (
                    createTestRow(noTests).map(row => row)
                ) : (
                    <tr>
                        <td colSpan={4} className="text-center">There are currently no tests available</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default TestReview;