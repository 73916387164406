import { connect } from "react-redux";
import { LPProps } from '../../types/learning'
import { faAngleLeft, faAngleRight, faBookOpen, faForward } from "@fortawesome/free-solid-svg-icons";
import Button from '../TheoryTest/Button';
import { setStudy, setSkipCorrect, nextQuestion, prevQuestion } from "../../actions/learning";

function LearningButtons(props: LPProps & {className?: string, fullScreenAvailable?: boolean}) {
    return (
        <div className={props.className}>
            {!props.skipCorrect || props.correct.length < (props.numQuestions[props.currentSection] - 1) ? <Button text="Previous" fn={() => props.prevQuestion()} icon={faAngleLeft} className={props.fullScreenAvailable ? 'ml-2' : 'w-1/4 inline-block'} /> : <div className={props.fullScreenAvailable ? 'm-2 mx-0' : 'w-1/4 inline-block'}></div>}
            <Button text="Study" fn={() => props.setStudy()}  className={`${props.study ? 'bg-blue-900' : ''} ${props.fullScreenAvailable ? '' : 'w-1/4 inline-block'}`} icon={faBookOpen} />
            <Button text="Skip Correct" fn={() => props.setSkipCorrect()} className={`${props.skipCorrect ? 'bg-blue-900' : ''} ${props.fullScreenAvailable ? '' : 'w-1/4 inline-block'}`} icon={faForward} />
            {!props.skipCorrect || props.correct.length < (props.numQuestions[props.currentSection] - 1) ? <Button text="Next" fn={() => props.nextQuestion()} icon={faAngleRight} className={props.fullScreenAvailable ? 'mr-2' : 'w-1/4 inline-block'} /> : <div className={props.fullScreenAvailable ? 'm-2 mx-0' : 'w-1/4 inline-block'}></div>}
        </div>
    )
}

const mapStateToProps = (state: any, myProps: {className?: string}): LPProps => {
    return {
        ...state,
        numQuestions: state.learning.numQuestions,
        currentSection: state.learning.currentSection,
        correct: state.learning.correct,
        skipCorrect: state.learning.skipCorrect,
        study: state.learning.study
    };
};

const mapDispatchToProps = { nextQuestion, prevQuestion, setStudy, setSkipCorrect }

export default connect<LPProps, {}>(mapStateToProps, mapDispatchToProps)(LearningButtons);