import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Spinner from "../Spinner";
import Login from "../Login";
import myLDC from "../../api/myldc";
import Card from "../Card";
import { logout } from "../../helpers";

export default function ListPupils() {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = localStorage.getItem('token');
        return token ? true : false;
    });
    const [pupils, setPupils] = useState([]);

    useEffect(() => {
        if(isAuthenticated !== true) {
            setPupils([])
        }
    }, [isAuthenticated]);

    const LogoutHelper = () => {
        logout();
        setIsAuthenticated(false);
    }

    if (pupils.length === 0 && isAuthenticated === true) {
        myLDC
            .get("/pupil/list")
            .then((response: AxiosResponse<any>) => {
                setPupils(response.data);
            })
            .catch(() => {
                setPupils([])
                setIsAuthenticated(false)
            });
        return <Spinner />;
    }

    if(isAuthenticated === true) {
        return <>
        <button onClick={LogoutHelper}>Logout</button>
        {pupils.length === 0
            ? <div>There are currently no pupils</div>
            : pupils.map((pupil: any) => (
                <Card key={pupil.id} onClick={() => console.log(pupil.id)}>
                    {pupil.firstname} {pupil.lastname}
                </Card>
            ))}
        </>
    }
    
    return <Login onSuccess={() => setIsAuthenticated(true)} />;
}
