import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Hazard } from "./hazard";
import { setHPTest, reviewHPTest } from "../../actions/hazard";
import HazardPerception from './HazardPerception';
import TestReview from "../TestReview";

type GetParams = {
    test: string | undefined;
}

function HazardPerceptionOverview(props: Hazard) {
    const { testNo, complete, setHPTest, reviewHPTest } = props;
    const { test } = useParams<GetParams>();

    useEffect(() => {
        
    }, [])

    if (testNo >= 1) {
        return <>{test}<HazardPerception testNo={testNo} /></>
    } else {
        return <>Test No: {test}<br /><TestReview noTests={12} score={75} testName="Hazard Perception" takeTest={setHPTest} reviewTest={reviewHPTest} userData={complete} /></>
    }
}

const mapStateToProps = (state: any): Hazard => {
    return {
        ...state,
        testNo: state.hazard.testNo,
        complete: state.hazard.complete
    };
};

const mapDispatchToProps = {
    setHPTest,
    reviewHPTest
};

export default connect<Hazard>(mapStateToProps, mapDispatchToProps)(HazardPerceptionOverview);
