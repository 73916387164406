import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Hazard } from "./hazard";
import {
    playPauseVideo,
    setPlaybackRate,
    setPauseOnScore,
} from "../../actions/hazard";

function VideoReviewControls({ videoStatus, playPauseVideo, setPlaybackRate, setPauseOnScore }: Hazard) {
    const playbackButtonStyles = 'flex-1 py-2 px-4 mr-1 font-semibold rounded-lg';
    return (
        <div className="flex flex-wrap md:flex-nowrap">
            <div className="block w-full mb-3 md:w-auto md:flex-1 text-center order-2 md:order-first">
            <button
                onClick={playPauseVideo}
                className={`py-2 px-4 ${
                    videoStatus.playing
                        ? "bg-yellow-500 hover:bg-yellow-700"
                        : "bg-green-500 hover:bg-green-700"
                } text-white font-semibold rounded-lg mr-3`}
            >
                <FontAwesomeIcon
                    icon={
                        videoStatus.playing ? faPause : faPlay
                    }
                />
            </button>
            <button
                onClick={setPauseOnScore}
                className={`py-2 px-4 bg-yellow-500 hover:bg-yellow-700 text-white font-semibold rounded-lg`}
            >
                <FontAwesomeIcon
                    icon={videoStatus.windowPause ? faCheck : faPause}
                    className={videoStatus.windowPause ? 'text-green-700' : ''}
                    fixedWidth={true}
                />{" "}
                Pause 5,4,3,2,1
            </button>
            </div>
            <div className="block w-full mb-3 md:w-auto md:flex-1 text-center order-first md:order-2 font-semibold">Score Window<div className="text-6xl">{videoStatus.scoreWindow}</div></div>
            <div className="block w-full mb-3 md:w-auto md:flex-1 order-3">
              <div className="flex">
                  <button
                      onClick={() => setPlaybackRate(0.25)}
                      className={`${videoStatus.playbackRate === 0.25 ? 'bg-blue-700' : 'bg-red-500 hover:bg-red-700'} text-white ${playbackButtonStyles}`}
                  >
                      0.25x
                  </button>
                  <button
                      onClick={() => setPlaybackRate(0.5)}
                      className={`${videoStatus.playbackRate === 0.5 ? 'bg-blue-700' : 'bg-red-500 hover:bg-red-700'} text-white ${playbackButtonStyles}`}
                  >
                      0.5x
                  </button>
                  <button
                      onClick={() => setPlaybackRate(1)}
                      className={`${videoStatus.playbackRate === 1 ? 'bg-blue-700' : 'bg-red-500 hover:bg-red-700'} text-white ${playbackButtonStyles}`}
                  >
                      1x
                  </button>
                  <button
                      onClick={() => setPlaybackRate(1.5)}
                      className={`${videoStatus.playbackRate === 1.5 ? 'bg-blue-700' : 'bg-red-500 hover:bg-red-700'} text-white ${playbackButtonStyles}`}
                  >
                      1.5x
                  </button>
                  <button
                      onClick={() => setPlaybackRate(2)}
                      className={`${videoStatus.playbackRate === 2 ? 'bg-blue-700' : 'bg-red-500 hover:bg-red-700'} text-white ${playbackButtonStyles}`}
                  >
                      2x
                  </button>
              </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any): Hazard => {
    return {
        ...state,
        videos: state.hazard.videos,
        videoStatus: state.hazard.videoStatus,
    };
};

const mapDispatchToProps = {
    playPauseVideo,
    setPlaybackRate,
    setPauseOnScore,
}

export default connect<Hazard>(mapStateToProps, mapDispatchToProps)(VideoReviewControls);
