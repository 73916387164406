import {
    SET_HP_TEST,
    SET_VIDEOS,
    NEXT_VIDEO,
    PREV_VIDEO,
    GO_TO_VIDEO,
    ADD_CLICK_TIME,
    EXIT_HP_TEST,
    PLAY_VIDEO,
    PAUSE_VIDEO,
    PLAY_PAUSE_VIDEO,
    PLAYBACK_RATE,
    SET_DURATION,
    SET_ERROR,
    SCORE_PAUSE,
    SEEK_CHANGE,
    SEEK_START,
    SEEK_END,
    SET_PLAYED,
    BUFFERING,
    VIDEO_READY,
    VIDEO_ENDED,
    RESET_TEST,
    REVIEW_HP_TEST
} from "./types";

export const setHPTest = (testNo: number) => {
    return {
        type: SET_HP_TEST,
        payload: testNo
    };
}

export const setVideos = (videos: any[], testId: number) => {
    return {
        type: SET_VIDEOS,
        payload: {
            videos,
            testId
        },
    };
};

export const nextVideo = () => {
    return {
        type: NEXT_VIDEO,
    };
};

export const prevVideo = () => {
    return {
        type: PREV_VIDEO,
    };
};

export const goToVideo = (video: number) => {
    return {
        type: GO_TO_VIDEO,
        payload: video
    };
};

export const addVideoClick = (time: number) => {
    return {
        type: ADD_CLICK_TIME,
        payload: time,
    };
};

export const exitHPTest = () => {
    return {
        type: EXIT_HP_TEST
    };
};

export const playPauseVideo = () => {
    return {
        type: PLAY_PAUSE_VIDEO
    }
}

export const playVideo = () => {
    return {
        type: PLAY_VIDEO
    }
}

export const pauseVideo = () => {
    return {
        type: PAUSE_VIDEO
    }
}

export const setError = () => {
    return {
        type: SET_ERROR
    }
}

export const setDuration = (duration: number) => {
    return {
        type: SET_DURATION,
        payload: duration
    }
}

export const setPlayed = (e: any) => {
    return {
        type: SET_PLAYED,
        payload: e.played
    }
}

export const setPlaybackRate = (rate: number) => {
    return {
        type: PLAYBACK_RATE,
        payload: rate
    }
}

export const setPauseOnScore = () => {
    return {
        type: SCORE_PAUSE
    }
}

export const setSeekChange = (e: {target: HTMLInputElement}) => {
    return {
        type: SEEK_CHANGE,
        payload: e.target.value
    }
}

export const setSeekStart = () => {
    return {
        type: SEEK_START
    }
}

export const setSeekEnd = () => {
    return {
        type: SEEK_END
    }
}

export const setBuffering = () => {
    return {
        type: BUFFERING
    }
}

export const setVideoReady = () => {
    return {
        type: VIDEO_READY
    }
}

export const setVideoEnded = () => {
    return {
        type: VIDEO_ENDED
    }
}

export const resetHPTest = () => {
    return {
        type: RESET_TEST
    }
}

export const reviewHPTest = (testNo: number) => {
    return {
        type: REVIEW_HP_TEST,
        payload: testNo
    }
}