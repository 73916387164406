import { useState, useEffect } from 'react'
import Search from '../Search'
import Spinner from '../Spinner';
import myLDC from '../../api/myldc'
import { AxiosResponse } from 'axios';
import InstructorInfo, { InstructorDetails } from './InstructorInfo';

export default function ListInstructors() {
    const [loading, setLoading] = useState(false);
    const [instructors, setInstructors] = useState([]);
    const [search, setSearch] = useState('');
    
    useEffect(() => {
        if(search.length >= 1){
            setLoading(true);
            myLDC.get(`/instructors/search/${encodeURIComponent(search).toLowerCase()}`).then((response: AxiosResponse<any>) => {
                setInstructors(response.data);
                setLoading(false);
            });
        } else {
            setInstructors([]);
        }
    }, [search])

    return (
        <>
            <Search searchEvent={setSearch} />
            {loading === true
                ? <Spinner />
                : instructors.length === 0
                    ? <div>Sorry, no instructors found{search.length > 1 ? ` for postcode ${search}` : ''}</div>
                    : instructors.map((instructor: InstructorDetails) => <InstructorInfo key={instructor.id} data={instructor} />)
            }
        </>
    )
}
