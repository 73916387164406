import { connect } from "react-redux";
import _ from 'lodash'
import { TheoryFunctionProps, TheoryProps } from '../../types/theory';
import { goToQuestion } from "../../actions";

function TestQuestionReviewButtons(props: TheoryProps & TheoryFunctionProps) {
    if (props.review !== true) {
        return null;
    }
    let keys = _.keys(props.questions[props.testNo]);
    return (
        <div>
            {keys.map((question) => (
                <div
                    key={question}
                    onClick={() =>
                        props.goToQuestion(parseInt(question) + 1)
                    }
                    className={`my-1 cursor-pointer font-bold border-4 ${
                        parseInt(question) ===
                        (props.currentQuestion - 1)
                            ? "border-black"
                            : "border-transparent"
                    } ${
                        props.ended === true
                            ? _.includes(
                                    props.correct[props.testNo],
                                    parseInt(question) + 1
                                )
                                ? "bg-green-correct"
                                : _.includes(
                                        props.incorrect[props.testNo],
                                        parseInt(question) + 1
                                    )
                                ? "bg-red-incorrect"
                                : "bg-gray-100"
                            : ""
                    } shadow text-center font-medium inline-block w-11 mr-1.5 sm:w-12 sm:mr-3.5 lg:w-10 xl:w-12 lg:mr-2.5 xl:mr-3`}
                >
                    {parseInt(question) + 1}
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = (state: any): TheoryProps & TheoryFunctionProps => {
    return {
        ...state,
        testNo: state.question.testNo,
        questions: state.question.questions,
        currentQuestion: state.question.currentQuestion,
        review: state.question.review,
        ended: state.question.ended,
        correct: state.question.correct,
        incorrect: state.question.incorrect
    };
};

const mapDispatchToProps = { goToQuestion }

export default connect<TheoryProps & TheoryFunctionProps>(mapStateToProps, mapDispatchToProps)(TestQuestionReviewButtons);