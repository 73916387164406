import { useEffect } from "react";
import { connect } from "react-redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useBeforeunload } from 'react-beforeunload';

import "./HazardPerception.css";
import myLDC from "../../api/myldc";
import FullscreenButton from "../FullscreenButton";
import { Hazard } from "./hazard";
import VideoPlayer from "./VideoPlayer";
import ScoreWindow from "./ScoreWindow";
import Flags from "./Flags";
import Review from "./Review";
import VideoProgress from "./VideoProgress";
import VideoReviewControls from "./VideoReviewControls";
import {
    setVideos,
    nextVideo,
    prevVideo,
    goToVideo,
    addVideoClick,
    exitHPTest,
    resetHPTest
} from "../../actions/hazard";
import Spinner from "../Spinner";

function HazardPerception(props: Hazard) {
    const {
        testNo,
        review,
        videos,
        passMark,
        numVideos,
        currentVideo,
        scores,
        ended,
        videoStatus,
        videoClicks,
        tamper,
        setVideos,
        prevVideo,
        nextVideo,
        exitHPTest,
        goToVideo,
        resetHPTest
    } = props;
    const handle = useFullScreenHandle();

    const SaveProgress = () => {
        // localStorage.setItem('HelloWorld', 'mydata');
    }

    useEffect(() => {
        return () => {
            SaveProgress();
        }
    }, [])

    useBeforeunload((event: MouseEvent) => {
        SaveProgress();
    });

    if (typeof(numVideos[testNo]) === 'undefined' || numVideos[testNo] <= 0) {
        myLDC.get(`hazard/${testNo}`).then((response: AxiosResponse<any>) => {
            setVideos!(response.data, testNo);
        });
        return <Spinner />;;
    }

    return (
        <FullScreen
            handle={handle}
            className={`HPTest select-none relative ${
                ended && review ? (handle.active === true ? "bg-white" : "") : "bg-black text-white max-h-[85vh]"
            }`}
        >
            {!review ? (
                <div>
                    <div className={`grid grid-cols-12 ${!ended ? "absolute top-0 z-30 w-full font-bold" : ""}`}>
                        <div className="p-3">
                            {currentVideo}/{numVideos[testNo]}
                        </div>
                        <div className="col-span-10 p-3 text-center font-semibold">
                            {" "}
                            {ended ? (
                                <>
                                    You scored{" "}
                                    {scores[testNo][currentVideo - 1]
                                        ? scores[testNo][currentVideo - 1]
                                        : 0}{" "}
                                    {videos[testNo][currentVideo - 1].nohazards > 1
                                        ? `for the first hazard and ${
                                              scores[testNo][videos.length + 1]
                                                  ? scores[testNo][videos[testNo].length + 1]
                                                  : 0
                                          } for the second hazard`
                                        : "for this hazard"}
                                </>
                            ) : null}
                        </div>
                        <div className="text-right p-3"><FullscreenButton handle={handle} /></div>
                    </div>
                    <div className="grid grid-cols-12 gap-4">
                        {ended ? (
                            <div className="px-3 col-span-12 md:col-span-3">
                                <button
                                    onClick={prevVideo}
                                    className="y-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md inline-block mr-3 w-10 h-10"
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </button>
                                <button
                                    onClick={nextVideo}
                                    className="y-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md w-10 h-10"
                                >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </button>
                                <button
                                    onClick={exitHPTest}
                                    className="y-2 bg-red-500 hover:bg-red-700 text-white font-semibold rounded-lg shadow-md w-10 h-10 float-right"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                                <br />
                                <div className="font-semibold">
                                    {videos[testNo][currentVideo - 1].title}
                                </div>
                                {videos[testNo][currentVideo - 1].description}{" "}
                                {videos[testNo][currentVideo - 1].description2}
                            </div>
                        ) : null}
                        <div
                            className={`col-span-12 ${
                                ended ? "md:col-span-9 pr-3" : ""
                            }`}
                        >
                            <VideoPlayer />
                            <Flags
                                videoClicks={videoClicks[testNo] ? videoClicks[testNo][currentVideo - 1] : []}
                                duration={videoStatus.duration}
                                ended={ended}
                            />
                            {ended ? (
                                <>
                                    <ScoreWindow
                                        videoInfo={videos[testNo][currentVideo - 1]}
                                        duration={videoStatus.duration}
                                    />
                                    <VideoProgress />
                                </>
                            ) : null}
                        </div>
                        {ended ? (
                            <div className="col-span-12 p-3 pt-0">
                                {tamper[testNo][currentVideo - 1] ? (
                                    <div id="anticheat" className="text-center">
                                        {tamper[testNo][currentVideo - 1]} Detected
                                    </div>
                                ) : (
                                    ""
                                )}
                                <VideoReviewControls />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="grid grid-cols-1">
                        <div className="text-right p-3"><FullscreenButton handle={handle} /></div>
                    </div>
                    <Review
                        videos={videos[testNo]}
                        scores={scores[testNo]}
                        tamper={tamper[testNo]}
                        clicks={videoClicks[testNo]}
                        passMark={passMark}
                        goToVideo={goToVideo}
                        testNo={testNo}
                    />
                    <button onClick={resetHPTest}>Reset Test</button>
                </div>
            )}
        </FullScreen>
    );
}

interface PassedProps {
    testNo: number
}

const mapStateToProps = (state: any, ownProps?: any): Hazard => {
    return {
        ...state,
        testNo: ownProps.testNo,
        videos: state.hazard.videos,
        videoClicks: state.hazard.videoClicks,
        currentVideo: state.hazard.currentVideo,
        videoStatus: state.hazard.videoStatus,
        numVideos: state.hazard.numVideos,
        passMark: state.hazard.passMark,
        scores: state.hazard.scores,
        tamper: state.hazard.tamper,
        review: state.hazard.review,
        ended: state.hazard.ended,
    };
};

const mapDispatchToProps = {
    setVideos,
    nextVideo,
    prevVideo,
    goToVideo,
    addVideoClick,
    exitHPTest,
    resetHPTest
};

export default connect<Hazard, {}, PassedProps>(
    mapStateToProps,
    mapDispatchToProps
)(HazardPerception);
