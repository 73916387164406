import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ButtonTypes {
    text: string,
    fn: any | void,
    icon?: IconDefinition,
    iconClass?: string,
    className?: string,
    disabled?: boolean
}

export default function Button({text, fn, icon, iconClass, className, disabled}: ButtonTypes) {
    return (
        <div
            onClick={fn}
            className={`align-middle select-none m-2 mx-0 ring-4 transition duration-150 ease-in-out transform py-2 hover:shadow-lg text-white ${disabled === true ? 'cursor-not-allowed ring-gray-900 bg-gray-600 ' : 'cursor-pointer ring-blue-900 hover:ring-blue-400 bg-blue-600 hover:bg-blue-900'} ${className}`}
        >
            <span className="inline-block align-middle">
                {icon ? <FontAwesomeIcon icon={icon} fixedWidth className={iconClass} /> : ''}
                <div className="block text-xs md:contents md:text-base"> {text}</div>
            </span>
        </div>
    )
}
