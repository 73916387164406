import Card from '../Card';

export interface InstructorDetails {
    id: number;
    name: string;
    email: string;
    website: string;
    car: string;
    gender: string;
    phone: string;
    mainarea: string;
    headerareas: string;
    areascovered: string;
    postcode_areas: string;
    postcodes: string;
    about: string | null;
    offers: object;
    lessons: object;
    social: object;
    lat: number;
    lng:number;
    manual: number;
    automatic: number;
    trainee: number;
    passplus: number;
    ecosafe: number;
    fleet: number;
    tutor: number;
    mentor: number;
    gsp: number;
    training: number;
    priceoverride:null,
    star_ratings: number;
    isactive: number;
    available: number;
    last_login: string | null;
    lastmodified: string | null;
    last_checked: string | null;
    last_synced: string | null;
    display_stats: number;
    distance: number;
    firstname: string;
    testimonials: boolean;
}

export default function InstructorInfo({data} : {data: InstructorDetails}) {
    return (
        <Card>
            <p>Hi I'm {data.firstname}<br />I'm a {data.gender === 'F' ? 'female ' : null}driving instructor {data.trainee === 1 ? 'licenced' : 'approved'} by the DVSA offering driving lessons in {data.headerareas}</p>
        </Card>
    )
}
