import { TheoryQuestions } from "../../types/theory";

export default function QuestionCase(props: {questionInfo: TheoryQuestions}) {
    if(!props.questionInfo.case) {
        return null;
    }

    if(!props.questionInfo.case!.video) {
        return <div className="bg-gray-400 h-full p-3 mt-3 md:mt-0 font-semibold">{props.questionInfo.case!.situation}</div>;
    }

    return (
        <></>
        // <ReactPlayer
        //     ref={player}
        //     muted={true}
        //     controls
        //     url={`https://www.theory-test-online.co.uk/videos/case/mp4/${props.questionInfo.case!.video}.mp4`}
        //     className="mx-auto h-11/12 md:w-11/12"
        //     width="auto"
        //     height="auto"
        //     config={{
        //         // @ts-ignore
        //         attributes: {
        //             style: {width: "100%"},
        //             controlsList: "nodownload nofullscreen noremoteplayback",
        //             muted: "",
        //             disablePictureInPicture: true
        //         }
        //     }}
        // />
    );
}
