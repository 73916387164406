import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AxiosResponse } from "axios";
import ProgressBar from "./Progress";
import { TheoryProps } from "../../types/theory";
import myLDC from "../../api/myldc";

interface SectionResults {
    section: number,
    name: string,
    free: number,
    num_questions: number
}

function TestResults(props: TheoryProps) {
    const { testNo, numQuestions, passMark, correct, incorrect, flagged, dvsa, complete } = props;
    const [sections, setSections] = useState<SectionResults[] | []>(() => {
        const savedData = localStorage.getItem('dvsa_sections');
        const values = savedData ? JSON.parse(savedData) : null;
        return values || [];
    });

    useEffect(() => {

    }, []);

    if (sections.length === 0) {
        myLDC.get("learning/list").then((response: AxiosResponse<any>) => {
            setSections(response.data);
            localStorage.setItem('dvsa_sections', JSON.stringify(response.data))
        })
    }

    return (
        <div className="text-center p-4 bg-yellow-100">
            <h1 className="font-bold text-2xl uppercase">Theory Test {testNo} Report</h1>
            {correct[testNo].length >= passMark
                ? <h2 className="font-bold text-2xl my-3 text-green-600">Congratulations, you've passed</h2>
                : <h2 className="font-bold text-2xl my-3 text-red-600">Sorry, but you've failed the test this time</h2>
                    }
            <p>
                You answered {correct[testNo].length} out of{" "}
                {numQuestions[testNo]} questions correctly, the pass rate is{" "}
                {passMark} out of {numQuestions[testNo]}
            </p>
            <p>
                <strong>Date:</strong>{" " + complete[testNo].date}
            </p>
            <p>
                <strong>Questions:</strong> {numQuestions[testNo]}
            </p>
            <p>
                <strong>Status:</strong>{" "}
                <span
                    className={`font-bold ${
                        correct[testNo].length >= passMark
                            ? "text-green-600"
                            : "text-red-600"
                    }`}
                >
                    Failed
                </span>
            </p>
            <p>
                <strong>Time Taken:</strong>{" " + complete[testNo].taken}
            </p>
            <div className="p-3 grid grid-cols-6 gap-2 mt-8">
                <div className="col-span-2 font-extrabold">Correct:</div>
                <div className="font-bold">{correct[testNo].length} / {numQuestions[testNo]}</div>
                <div className="col-span-3"><ProgressBar
                    percentage={
                        (correct[testNo].length / numQuestions[testNo]) * 100
                    }
                /></div>
            </div>
            <div className="p-3 grid grid-cols-6 gap-2">
                <div className="col-span-2 font-extrabold">Incorrect:</div>
                <div className="font-bold">{incorrect[testNo].length} / {numQuestions[testNo]}</div>
                <div className="col-span-3"><ProgressBar
                    percentage={
                        (incorrect[testNo].length / numQuestions[testNo]) * 100
                    }
                    bgColor="bg-red-600"
                /></div>
            </div>
            <div className="p-3 grid grid-cols-6 gap-2">
                <div className="col-span-2 font-extrabold">Flagged:</div>
                <div className="font-bold">{flagged[testNo].length} / {numQuestions[testNo]}</div>
                <div className="col-span-3">
                <ProgressBar
                    percentage={
                        (flagged[testNo].length / numQuestions[testNo]) * 100
                    }
                    bgColor="bg-blue-600"
                />
                </div>
            </div>
            <div className="p-3 grid grid-cols-6 gap-2">
                <div className="col-span-2 font-extrabold">Incomplete:</div>
                <div className="font-bold">{numQuestions[testNo] - (correct[testNo].length + incorrect[testNo].length)} / {numQuestions[testNo]}</div>
                <div className="col-span-3">
                <ProgressBar
                    percentage={
                        ((numQuestions[testNo] -
                            (correct[testNo].length +
                                incorrect[testNo].length)) /
                            numQuestions[testNo]) *
                        100
                    }
                    bgColor="bg-yellow-600"
                />
                </div>
            </div>
            {sections.length >= 1 ? (
                <table className="table-auto w-full border bg-white mt-8">
                    <thead>
                        <tr className="font-extrabold bg-gray-300">
                            <td className="p-1.5">DVSA Category</td>
                            <td className="text-center p-1.5">Correct</td>
                            <td className="text-center p-1.5">Incorrect</td>
                            <td className="text-center p-1.5">Total</td>
                            <td className="text-center p-1.5">% Correct</td>
                        </tr>
                    </thead>
                    <tbody>
                        {sections.map(section => <tr key={section.section} className="border border-l-0 border-r-0">
                            <td className="p-1.5 text-left">{section.section}. {section.name}</td>
                            <td className="text-center p-1.5">{dvsa[testNo][section.section] ? dvsa[testNo][section.section]['correct'].length : 0}</td>
                            <td className="text-center p-1.5">{dvsa[testNo][section.section] ? dvsa[testNo][section.section]['numQuestions'] - dvsa[testNo][section.section]['correct'].length : 0}</td>
                            <td className="text-center p-1.5">{dvsa[testNo][section.section] ? dvsa[testNo][section.section]['numQuestions'] : 0}</td>
                            <td className="text-center p-1.5">{dvsa[testNo][section.section] ? Math.floor((dvsa[testNo][section.section]['correct'].length / dvsa[testNo][section.section]['numQuestions']) * 100) : null}%</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            ) : null}
        </div>
    );
}

const mapStateToProps = (state: any): TheoryProps => {
    return {
        ...state,
        testNo: state.question.testNo,
        numQuestions: state.question.numQuestions,
        correct: state.question.correct,
        incorrect: state.question.incorrect,
        flagged: state.question.flagged,
        passMark: state.question.passMark,
        dvsa: state.question.dvsa,
        complete: state.question.complete
    };
};

export default connect<TheoryProps, {}>(mapStateToProps)(TestResults);
