import _ from 'lodash'
import TextToSpeech from './TextToSpeech'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface OptionTypes {
    options: any,
    correct: string,
    amount: number,
    selected: string | null,
    voiceOver: boolean,
    review?: (boolean | string),
    ended?: boolean,
    learning?: boolean,
    fullScreenAvailable?: boolean,
    addAnswer: (a: string, b: string, c: number | null) => void,
    removeAnswer: (a: string, b: number | null) => void,
}

export default function QuestionOptions({options, correct, amount, selected, voiceOver, addAnswer, removeAnswer, review, ended, learning, fullScreenAvailable}: OptionTypes) {
    return (
        <div className={`clear-both ${options.format < 2 ? (options.format !== 1 ? 'lg:grid lg:grid-flow-col lg:grid-rows-2' : (fullScreenAvailable ? 'grid grid-flow-col grid-rows-2' : '')) : ''} flex-1 mt-6 md:mt-auto option select-none`}>
                {_.times(amount, (i) => (
                <div
                    key={i}
                    className={`py-2 px-1 md:px-2${fullScreenAvailable ? ' relative flex-1' : options.format === 1 ? 'w-1/2 inline-block' : ''} flex items-center font-semibold ${
                        review !== true ? "cursor-pointer" : ""
                    } ${
                        selected ===
                        String.fromCharCode(97 + i).toUpperCase()
                            ? review !== true && learning !== true
                                ? "bg-blue-300"
                                : String.fromCharCode(
                                        97 + i
                                    ).toUpperCase() === correct
                                ? "bg-green-correct"
                                : "bg-red-incorrect"
                            : ended !== true
                            ? "hover:bg-gray-100"
                            : String.fromCharCode(97 + i).toUpperCase() === correct ? "bg-green-100" : ""
                    }`}
                    onClick={() =>
                        review !== true
                            ? selected === 
                                String.fromCharCode(97 + i).toUpperCase() ? removeAnswer(String.fromCharCode(97 + i).toUpperCase(), options.dsacat) : addAnswer(
                                    String.fromCharCode(97 + i).toUpperCase(),
                                    correct,
                                    options.dsacat
                                )
                            : ""
                    }
                >
                    {options.format !== 1 ? <>
                        <div className={`w-12 h-12 md:w-16 md:h-16 inline-block mr-2 rounded shadow-lg text-center ${selected ===
                        String.fromCharCode(97 + i).toUpperCase() ? 'bg-gradient-to-br from-blue-500 via-blue-800 to-blue-900' : 'bg-blue-500'}`}>{review === true && String.fromCharCode(97 + i).toUpperCase() === correct ? <FontAwesomeIcon icon={faCheckCircle} fixedWidth className="w-full h-full" size="2x" /> : null}</div>
                        <div className="flex-1" dangerouslySetInnerHTML={{__html: options["option" + (i + 1)]}} />
                        <TextToSpeech text={options["option" + (i + 1)]} voiceOver={voiceOver}  />  
                    </> : <>
                        {review === true && String.fromCharCode(97 + i).toUpperCase() === correct ? <FontAwesomeIcon icon={faCheckCircle} fixedWidth className={`w-full ${fullScreenAvailable ? 'absolute' : ''} left-3 top-3`} size="2x" /> : null}
                        <img src={`/images/prim/${options.prim}${String.fromCharCode(97 + i).toLowerCase()}.png`} alt={options["option" + (i + 1)]} className="mx-auto w-full h-auto md:max-w-image" />
                    </>
                    }
                </div>
            ))}
        </div>
    )
}