import { TheoryQuestions } from '../../types/theory'

export default function QuestionImage(props: {questionInfo: TheoryQuestions}) {
    if (props.questionInfo.dsaimageid === null) {
        return <></>;
    }
    return (
        <img
            src={`/images/prim/${props.questionInfo.prim}.jpg`}
            alt="My Question"
            className="mx-auto py-2 pt-0 lg:my-0 lg:mx-0 lg:ml-2 lg:float-right max-h-56"
        />
    );
}
