import React from 'react';
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { setSectionNumber } from './actions/learning';
import { setTestNo } from './actions';
import { setHPTest } from './actions/hazard';
import HazardPerceptionOverview from './components/HazardPerception/HazardPerceptionOverview';
import TheoryTestOverview from './components/TheoryTest/TheoryTestOverview';
import SectionList from './components/LearningProgram/SectionList';
import HighwayCode from './components/HighwayCode/RuleList';
import ListPupils from './components/Instructors/ListPupils';
import ListInstructors from './components/Instructors/ListInstructors';

function App(props: any) {
  const {setSectionNumber, setTestNo, setHPTest } = props;
  return (
    <Router>
      <div className="container mx-auto">
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/theory" onClick={() => setTestNo(0)}>Theory</Link>
              </li>
              <li>
                <Link to="/learning" onClick={() => setSectionNumber(0)}>Learning Section</Link>
              </li>
              <li>
                <Link to="/hazard" onClick={() => setHPTest(0)}>Hazard</Link>
              </li>
              <li>
                <Link to="/highway-code">Highway Code</Link>
              </li>
              <li>
                <Link to="/instructors">Instructors</Link>
              </li>
              <li>
                <Link to="/students">Students</Link>
              </li>
            </ul>
          </nav>

          <Switch>
            <Route path="/learning">
              <SectionList list="dvsa" />
            </Route>
            <Route path="/theory">
              <TheoryTestOverview />
            </Route>
            <Route path="/hazard/:test">
              <HazardPerceptionOverview />
            </Route>
            <Route path="/hazard">
              <HazardPerceptionOverview />
            </Route>
            <Route path="/highway-code">
              <HighwayCode />
            </Route>
            <Route path="/instructors">
              <ListInstructors />
            </Route>
            <Route path="/students">
              <ListPupils />
            </Route>
            <Route path="/">
              <h2>This is the home content</h2>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...state
})

const mapDispatchToProps = {
  setSectionNumber,
  setTestNo,
  setHPTest
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
