import { useState, useEffect } from "react";

const zeroPad = (num: number, places: any) => String(num).padStart(places, "0");

const secondsToHms = (d: number) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? zeroPad(h, 2) + ":" : "";
    const mDisplay = m > 0 ? zeroPad(m, 2) + ":" : "00:";
    const sDisplay = s > 0 ? zeroPad(s, 2) : "00";
    return hDisplay + mDisplay + sDisplay;
};

const Countdown = ({
    seconds,
    endTest,
    remaining
}: {
    seconds: number;
    endTest: Function;
    remaining: Function;
}) => {
    const [counter, setCounter] = useState(seconds);

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        remaining(secondsToHms(seconds - counter));
        if (counter <= 0) {
            endTest(secondsToHms(seconds - counter));
        }
        if (timer !== false) {
            return () => clearInterval(timer);
        }
    }, [counter, endTest, remaining, seconds]);

    return (
        <>
            Time<div className="hidden md:inline-block">&nbsp;Remaining</div>:{" "}
            <span className="font-bold">{secondsToHms(counter)}</span>
        </>
    );
};

export default Countdown;
