import { connect } from "react-redux";
import { Hazard } from "./hazard";
import { playVideo, exitHPTest } from "../../actions/hazard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPowerOff } from "@fortawesome/free-solid-svg-icons";

function Overlay({ testNo, ended, videoStatus, numVideos, currentVideo, tamper, playVideo, exitHPTest }: Hazard) {
    const tamperTitle : { [key: string]: string } = {
        'Rapid clicking': 'Rapid clicking detected',
        'Too many clicks': 'You clicked too may times',
        'Click pattern': 'A pattern was detected with your clicks'
    };

    const tamperTestInfo = tamper[testNo] ? tamper[testNo] : [];

    return ended === true || videoStatus.playing ? (
        <></>
    ) : (
        <>
            <div className="absolute bg-gray-800 opacity-70 z-20 inset-0"></div>
            <div className="absolute inline-table bg-white text-black z-30 inset-1 lg:top-28 max-w-6xl top m-auto align-middle text-sm md:text-base p-5 opacity-100">
                
                <h4 className="font-bold">{tamperTestInfo[currentVideo - 2] && videoStatus.played < 0.001 ? tamperTitle[tamperTestInfo[currentVideo - 2]] :  `Clip ${currentVideo} of ${numVideos[testNo]}`}</h4>
                {videoStatus.played > 0.004 ?
                    <>
                        <p className="my-2 mb-4">Buffering...</p>
                    </>
                : currentVideo !== 1 ? 
                    <>
                    {tamperTestInfo[currentVideo - 2] && videoStatus.played < 0.001
                        ? <p className="my-2 mb-4">You will score zero for this clip</p>
                        : <p className="my-2 mb-4">You've completed {currentVideo - 1} out of {numVideos[testNo]} clips</p>
                    }
                    </>
                 : (
                    <>
                        <p className="my-2">This test contains {numVideos[testNo]} clips. {numVideos[testNo] - 1} clips contain 1 developing hazard and 1 clip contains two where you will score points for both developing hazards.</p>
                        <p className="my-2">Click or tap anywhere on the video when you see a developing hazard. This is something that will cause you to take action such as changing speed or direction.</p>
                        <p className="my-2">A red flag will appear at the bottom of the screen to show your response has been registered. You can score up to 5 points for each developing hazard so the earlier you recognise and respond to them the better.</p>
                        <p className="my-2 mb-4">You won’t lose points for responding to things may not develop into a hazard. But don’t click too often, or in a pattern, otherwise you may see a warning message and you won’t score any points for that clip.</p>
                    </>
                )}
                
                {currentVideo !== 1 ? <button
                    onClick={exitHPTest}
                    className={`py-2 px-4 bg-red-600 hover:bg-red-800 text-white font-semibold shadow-md mr-5`}
                >
                    <FontAwesomeIcon icon={faPowerOff} fixedWidth />
                    {" "}Exit Test
                </button> : ''}
                {videoStatus.loaded ? <button
                    onClick={playVideo}
                    className="py-2 px-4 bg-green-600 hover:bg-green-800 text-white font-semibold shadow-md">{" "}
                    {videoStatus.played > 0.004 ? 'Continue' : currentVideo !== 1 ? 'Start Next Clip' : 'Start Test'} <FontAwesomeIcon icon={faPlay} fixedWidth />
                </button> : <button className="py-2 px-4 bg-gray-500 ">Loading...</button>}
            </div>
        </>
    );
}

const mapStateToProps = (state: any): Hazard => {
    return {
        ...state,
        testNo: state.hazard.testNo,
        ended: state.hazard.ended,
        videoStatus: state.hazard.videoStatus,
        numVideos: state.hazard.numVideos,
        currentVideo: state.hazard.currentVideo,
        tamper: state.hazard.tamper
    };
}

const mapDispatchToProps = { playVideo, exitHPTest }

export default connect<Hazard, {}>(mapStateToProps, mapDispatchToProps)(Overlay);
