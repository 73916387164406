import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AxiosResponse } from "axios";
import { useBeforeunload } from 'react-beforeunload';

import { setTestNo, setQuestions, toggleVoiceover, addAnswer, removeAnswer, endTest } from "../../actions/";
import { TheoryProps, TheoryFunctionProps } from '../../types/theory';
import myLDC from "../../api/myldc";
import FullscreenButton from "../FullscreenButton";
import Countdown from "./Countdown";
import TextToSpeech from "./TextToSpeech";
import TestOverview from "./TestOverview";
import TestResults from "./TestResults";
import AlertBanner from "./AlertBanner";
import QuestionOptions from "./QuestionOptions";
import TestButtons from "./TestButtons";
import QuestionImage from "./QuestionImage";
import MarkNumAnswers from "./MarkNumAnswers";
import TestQuestionReviewButtons from "./TestQuestionReviewButtons";
import QuestionCase from "./QuestionCase";
import Spinner from "../Spinner";

function TheoryTest(props: TheoryProps & TheoryFunctionProps) {
    const { testNo, numQuestions, questions, currentQuestion, answers, ended, review, voiceOver, overview, addAnswer, removeAnswer, toggleVoiceover, endTest } = props;
    const [remaining, setRemaining] = useState(0);
    const handle = useFullScreenHandle();
    // @ts-ignore
    const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;

    const SaveProgress = () => {
        const savedData = localStorage.getItem('ldc_theory');
        if(savedData) {
            //myLDC.post('theory/hello', JSON.parse(savedData))
        }
    }

    useEffect(() => {
        return () => {
            SaveProgress();
        }
    }, [remaining])

    useBeforeunload((event: MouseEvent) => {
        SaveProgress();
    });
    
    if (typeof(numQuestions[testNo]) === 'undefined' || numQuestions[testNo] <= 0) {
        myLDC.get(`theory/${testNo}`).then((response: AxiosResponse<any>) => {
            props.setQuestions(response.data, testNo);
        })
        return <Spinner />
    }

    return (
        <FullScreen handle={handle} className={`bg-yellow-100 select-none rounded min-h-theory ${fullScreenAvailable ? 'relative' : ''}`}>
            <div className={`flex flex-col inset-0 bg-yellow-100 top-0 ${fullScreenAvailable ? 'absolute' : ''}`}>
                <div className="bg-gray-400 p-2 flex">
                    <div className="flex-1 font-bold h-full"><span className="hidden sm:inline-block">Theory&nbsp;</span>Test {testNo}</div>
                    {overview !== 'results' ? <div className="flex-1 text-center font-bold">Q<div className="hidden md:contents">uestion </div>{currentQuestion} of {numQuestions[testNo]}</div> : null}
                    <div className="flex-1 text-right">{ended !== true ? <Countdown seconds={3420} endTest={endTest} remaining={setRemaining} /> : null}</div>
                    <div className="ml-2 md:ml-4"><FullscreenButton handle={handle} /></div>
                </div>
                {overview !== false ? (overview === "summary" ? <TestOverview /> : <TestResults />) : <div className={`${fullScreenAvailable ? 'flex-1 ' : ''}flex flex-col items-stretch bg-yellow-100 p-3`}>
                    <TestQuestionReviewButtons />
                    <AlertBanner review={review} />
                    {questions[testNo][currentQuestion - 1].format < 2 ? (
                        <>
                            <MarkNumAnswers mark={questions[testNo][currentQuestion - 1].mark} />
                            <div className="flex-1">
                                <QuestionImage questionInfo={questions[testNo][currentQuestion - 1]} />
                                <TextToSpeech text={questions[testNo][currentQuestion - 1].question} voiceOver={voiceOver} />
                                <div dangerouslySetInnerHTML={{__html: questions[testNo][currentQuestion - 1].question}} className="font-semibold" />
                            </div>
                        </>
                    ) : <MarkNumAnswers mark={questions[testNo][currentQuestion - 1].mark} />
                    }
                    {questions[testNo][currentQuestion - 1].format >= 2 ? (
                        <div className="md:flex flex-1 mt-3">
                            <div className="md:flex-1">
                                <QuestionCase questionInfo={questions[testNo][currentQuestion - 1]} />
                            </div>
                            <div className={`md:flex-1 px-0 md:pl-3 flex flex-col ${fullScreenAvailable ? 'relative' : ''}`}>
                                <div className={`flex-none ${fullScreenAvailable ? 'absolute' : ''} block right-0 lg:right-2 top-4 md:top-0`}><TextToSpeech text={questions[testNo][currentQuestion - 1].question} voiceOver={voiceOver} /></div>
                                <div dangerouslySetInnerHTML={{__html: questions[testNo][currentQuestion - 1].question}} className="font-semibold pt-4 md:pt-0 pb-4 flex-1 pr-9" />
                                <QuestionOptions
                                    options={questions[testNo][currentQuestion - 1]}
                                    correct={questions[testNo][currentQuestion - 1].answerletters}
                                    amount={questions[testNo][currentQuestion - 1].tickamount}
                                    selected={answers[testNo][currentQuestion - 1]}
                                    addAnswer={addAnswer}
                                    removeAnswer={removeAnswer}
                                    voiceOver={voiceOver}
                                    ended={ended}
                                    review={review}
                                />
                            </div>
                        </div>
                    ) : 
                    <QuestionOptions
                        options={questions[testNo][currentQuestion - 1]}
                        correct={questions[testNo][currentQuestion - 1].answerletters}
                        amount={questions[testNo][currentQuestion - 1].tickamount}
                        selected={answers[testNo][currentQuestion - 1]}
                        addAnswer={addAnswer}
                        removeAnswer={removeAnswer}
                        voiceOver={voiceOver}
                        fullScreenAvailable={fullScreenAvailable}
                        ended={ended}
                        review={review}
                    />}
                    <div className="mt-3">
                        <FontAwesomeIcon
                            icon={voiceOver ? faVolumeMute : faVolumeUp}
                            onClick={() => toggleVoiceover()}
                            className="cursor-pointer float-right"
                            size="lg"
                        />
                        <MarkNumAnswers mark={questions[testNo][currentQuestion - 1].mark} />
                    </div>
                </div>}
                <TestButtons className={`${fullScreenAvailable ? 'grid grid-cols-4 gap-3 ' : ''}font-bold text-center bg-gray-300 py-1 px-2 clear-both`} remaining={remaining} fullScreenAvailable={fullScreenAvailable} />
            </div>
        </FullScreen>
    );
}

interface PassedProps {
    testNo: number
}

const mapStateToProps = (state: any): TheoryProps & TheoryFunctionProps => {
    return {
        ...state,
        currentQuestion: state.question.currentQuestion,
        voiceOver: state.question.voiceOver,
        passMark: state.question.passMark,
        numQuestions: state.question.numQuestions,
        answers: state.question.answers,
        correct: state.question.correct,
        incorrect: state.question.incorrect,
        review: state.question.review,
        overview: state.question.overview,
        ended: state.question.ended,
        questions: state.question.questions
    };
};

const mapDispatchToProps = { setTestNo, setQuestions, toggleVoiceover, addAnswer, removeAnswer, endTest }

export default connect<TheoryProps & TheoryFunctionProps, {}, PassedProps>(mapStateToProps, mapDispatchToProps)(TheoryTest);