import Card from "../Card"
import TextToSpeech from '../TheoryTest/TextToSpeech'

const Rule = (props: {children: string, image: string | null, imagefooter: string | null}) => {
    return (
        <Card>
            <TextToSpeech text={props.children.replace(/<[^>]+>/g, '')} voiceOver={true} />
            <span dangerouslySetInnerHTML={{ __html: props.children}}></span>
            {props.image ? <img src={`/images/highway-code/${props.image}`} alt="This is a rule" className="mx-auto" /> : null}
            {props.imagefooter ? <p className="text-center">{props.imagefooter}</p> : null}
        </Card>
    )
}

export default Rule