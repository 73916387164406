const ProgressBar = (props: { percentage: number, bgColor?: string }) => {
	return (
		<div className='w-full bg-gray-300 rounded overflow-hidden'>
			<div
				style={{ width: `${props.percentage}%` }}
				className={`h-full font-bold overflow-hidden ${
					props.bgColor ? props.bgColor : 'bg-green-600'
				}`}
			>{props.percentage}%</div>
		</div>
	);
};

export default ProgressBar;