import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { connect } from "react-redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useBeforeunload } from "react-beforeunload";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setSection, addLPAnswer, removeLPAnswer } from "../../actions/learning";
import { toggleVoiceover } from "../../actions";
import myLDC from "../../api/myldc";
import QuestionImage from "../TheoryTest/QuestionImage";
import QuestionCase from "../TheoryTest/QuestionCase";
import QuestionOptions from "../TheoryTest/QuestionOptions";
import TextToSpeech from "../TheoryTest/TextToSpeech";
import MarkNumAnswers from "../TheoryTest/MarkNumAnswers";
import LearningButtons from "./LearningButtons";
import { LPProps, SectionType } from "../../types/learning";
import Study from "./Study";
import Spinner from "../Spinner";
import FullscreenButton from "../FullscreenButton";

function LearningProgram(props: LPProps) {
    const {section, numQuestions, questions, answers, currentQuestion, currentSection, study, voiceOver, setSection, addLPAnswer, removeLPAnswer, toggleVoiceover} = props;
    const handle = useFullScreenHandle();
    const [sections] = useState<SectionType[] | []>(() => {
        const savedData = localStorage.getItem(`dvsa_sections`);
        const values = savedData ? JSON.parse(savedData) : null;
        return values || [];
    });
    // @ts-ignore
    const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;

    useBeforeunload((event: any) => {
        event.preventDefault();
        return "Saving.....";
    });

    if (typeof numQuestions[section] === "undefined" || numQuestions[section] === null) {
        myLDC
            .get(`learning/questions?section=${section}`)
            .then((response: AxiosResponse<any>) => {
                setSection(response.data, section);
            });
        return <Spinner />;
    }

    return (
        <FullScreen
            handle={handle}
            className={`bg-gray-300 select-none min-h-theory ${fullScreenAvailable ? 'relative' : ''} ${
                handle.active ? " h-screen" : ""
            }`}
        >
            <div className={`flex flex-col min-h-full inset-0 ${fullScreenAvailable ? 'absolute' : ''}`}>
                <div className="bg-gray-400 flex p-2 px-4 font-bold">
                    <div className="flex-grow">{currentSection}. {sections[currentSection - 1].name}</div>
                    <div className="flex-shrink text-right">
                        Q<div className="hidden md:contents">uestion </div>
                        {currentQuestion}/{numQuestions[currentSection]}
                    </div>
                    <div className="ml-2 md:ml-4"><FullscreenButton handle={handle} /></div>
                </div>
                <div className={`p-3 pb-0 clear-both ${fullScreenAvailable ? 'flex-1 ' : ''} flex flex-col bg-gray-300`}>
                    {questions[currentSection][currentQuestion - 1].format < 2 ? (
                            <div className="flex-1 mb-5 lg:mb-0">
                                <MarkNumAnswers
                                    mark={questions[currentSection][currentQuestion - 1].mark}
                                />
                                <QuestionImage questionInfo={questions[currentSection][currentQuestion - 1]}/>
                                <TextToSpeech
                                    text={questions[currentSection][currentQuestion - 1].question}
                                    voiceOver={voiceOver}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: questions[currentSection][
                                            currentQuestion - 1
                                        ].question,
                                    }}
                                    className="font-semibold"
                                />
                            </div>
                        ) : <MarkNumAnswers
                        mark={questions[currentSection][currentQuestion - 1].mark}
                    />}
                    {questions[currentSection][currentQuestion - 1].format >= 2 ? (
                        <div className="md:flex flex-1 mt-3">
                            <div className="md:flex-1">
                                <QuestionCase questionInfo={questions[currentSection][currentQuestion - 1]} />
                            </div>
                            <div className={`md:flex-1 px-0 md:pl-3 flex flex-col ${fullScreenAvailable ? 'relative' : ''}`}>
                                <div className={`flex-none ${fullScreenAvailable ? 'absolute' : ''} block right-0 lg:right-2 top-4 md:top-0`}>
                                    <TextToSpeech
                                        text={questions[currentSection][currentQuestion - 1].question}
                                        voiceOver={voiceOver}
                                    />
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: questions[currentSection][
                                            currentQuestion - 1
                                        ].question,
                                    }}
                                    className="font-semibold pt-4 md:pt-0 pb-4 flex-1 pr-9"
                                />
                                <QuestionOptions
                                    options={questions[currentSection][currentQuestion - 1]}
                                    correct={
                                        questions[currentSection][currentQuestion - 1]
                                            .answerletters
                                    }
                                    amount={
                                        questions[currentSection][currentQuestion - 1]
                                            .tickamount
                                    }
                                    selected={
                                        answers[currentSection]
                                            ? answers[currentSection][currentQuestion - 1]
                                            : null
                                    }
                                    addAnswer={addLPAnswer}
                                    removeAnswer={removeLPAnswer}
                                    voiceOver={voiceOver}
                                    learning={true}
                                />
                            </div>
                        </div>
                    ) : <QuestionOptions
                        options={questions[currentSection][currentQuestion - 1]}
                        correct={
                            questions[currentSection][currentQuestion - 1]
                                .answerletters
                        }
                        amount={
                            questions[currentSection][currentQuestion - 1]
                                .tickamount
                        }
                        selected={
                            answers[currentSection]
                                ? answers[currentSection][currentQuestion - 1]
                                : null
                        }
                        addAnswer={addLPAnswer}
                        removeAnswer={removeLPAnswer}
                        fullScreenAvailable={fullScreenAvailable}
                        voiceOver={voiceOver}
                        learning={true}
                    />
                    }
                    <div className="mt-2">
                        <FontAwesomeIcon
                            icon={voiceOver ? faVolumeMute : faVolumeUp}
                            onClick={() => toggleVoiceover()}
                            className="cursor-pointer float-right"
                            size="lg"
                        />
                        <MarkNumAnswers
                            mark={
                                questions[currentSection][currentQuestion - 1]
                                    .mark
                            }
                        />
                    </div>
                    <Study
                        explanation={questions[currentSection][currentQuestion - 1].dsaexplanation}
                        visible={study}
                        voiceOver={voiceOver}
                    />
                </div>
                <LearningButtons className={`${fullScreenAvailable ? 'grid grid-cols-4 gap-3 ' : ''} font-bold text-center pt-1 pb-2 clear-both px-2 bg-gray-300`} fullScreenAvailable={fullScreenAvailable} />
            </div>
        </FullScreen>
    );
}

interface PassedProps {
    section: number;
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        ...state,
        section: ownProps.section,
        currentQuestion: state.learning.currentQuestion,
        currentSection: state.learning.currentSection,
        study: state.learning.study,
        numQuestions: state.learning.numQuestions,
        voiceOver: state.learning.voiceOver,
        questions: state.learning.questions,
        answers: state.learning.answers,
    };
};

const mapDispatchToProps = {
    setSection,
    addLPAnswer,
    removeLPAnswer,
    toggleVoiceover,
};

export default connect<LPProps, {}, PassedProps>(
    mapStateToProps,
    mapDispatchToProps
)(LearningProgram);
