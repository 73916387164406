interface ButtonProps {
    children: any;
    className?: string;
    [x:string]: any;
}

export default function Button(props: ButtonProps) {
    const { children, className, ...rest } = props;
    return (
        <button
            className={`py-2 px-4 ${className} text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 rounded-lg`}
            {...rest}
        >
            {children}
        </button>
    );
}
