import { useState } from 'react'
import { AxiosResponse } from "axios";
import myLDC from "../../api/myldc";
import './styles.css';
import Rule from './Rule';
import Spinner from '../Spinner';
import Button from '../Button';

interface HCSection {
    sec_no: number,
    title: string
}

interface HCRules {
    hcno: number,
    hcrule: string,
    hctitle: string|null;
    imagetitle1: string|null;
    imagetitle2: string|null;
    imagefooter1: string|null;
    image?: string|boolean;
} 

const RuleList = () => {
    const buttonColor = "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200";
    const [loading, setLoading] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);
    const [sections, setSections] = useState(() => {
        const savedData = localStorage.getItem('highway_code_sections');
        const values = savedData ? JSON.parse(savedData) : null;
        return values || [];
    });
    const [rules, setRules] = useState([]);

    const getSection = (section: number) => {
        setLoading(true);
        setCurrentSection(section);
        myLDC.get(`/hc/${section}`).then((response: AxiosResponse<any>) => {
            setRules(response.data);
            setLoading(false);
        });
    }


    if(sections.length === 0) {
        myLDC.get("/hc/sections").then((response: AxiosResponse<any>) => {
            localStorage.setItem('highway_code_sections', JSON.stringify(response.data));
            setSections!(response.data);
        });
        return <Spinner />;
    }

    if(loading === true) {
        return <Spinner />;
    }

    if(rules.length > 0) {
        let navButtons = (
            <>
            {currentSection > 1 ? <Button className={buttonColor} onClick={() => getSection(currentSection - 1)}>{sections[currentSection - 2].title} </Button> : null}
            {currentSection < sections.length ? <Button className={`${buttonColor} float-right`} onClick={() => getSection(currentSection + 1)}>{sections[currentSection].title}</Button> : null}
            <div className='clear-both' />
            </>
        )
        return <>
            <div className="my-3">{navButtons}</div>
            {rules.map((rule: HCRules) => {
                return <Rule key={rule.hcno} image={rule.imagetitle1} imagefooter={rule.imagefooter1}>{rule.hcrule}</Rule>
            })}
            <div className="my-3">{navButtons}</div>
        </>
    }
    return (
        <table className="table-auto w-full border bg-white rounded-lg shadow-md">
            <tbody>
            {sections.map((section: HCSection) => {
                return <tr key={section.sec_no} className="hover:bg-blue-500 hover:text-white cursor-pointer border border-l-0 border-r-0" onClick={() => getSection(section.sec_no)}>
                    <td className="p-1.5">{section.title}</td>
                </tr>
            })}
            </tbody>
        </table>
    )
}

export default RuleList