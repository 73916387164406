import "core-js/stable";

import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reducers from "./reducers";
import { createStore, applyMiddleware, compose } from "redux";
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const localStorageName = 'ldc_theory';
const localStorageInfo = localStorage.getItem(localStorageName);
const persistedState = localStorageInfo ? JSON.parse(localStorageInfo) : {}
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware()));


// import { createStore } from "redux";

// const localStorageName = 'ldc_theory';
// const localStorageInfo = localStorage.getItem(localStorageName);
// const persistedState = localStorageInfo ? JSON.parse(localStorageInfo) : {}
// const store = createStore(reducers, persistedState);

store.subscribe(()=>{
    localStorage.setItem(localStorageName, JSON.stringify(store.getState()))
})

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
