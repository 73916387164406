import { Videos } from './hazard';

type Props = {
  videoInfo: Videos,
  duration: number
}

function ScoreWindow({videoInfo, duration}: Props) {
  
    const marginLeft = (((videoInfo.prehazard ? videoInfo.prehazard : videoInfo.five) / duration) * 100);
    const secondMarginLeft = videoInfo.nohazards > 1 ? ((((videoInfo.prehazard2 ? videoInfo.prehazard2! : videoInfo.ten!) - videoInfo.endseq) / duration) * 100) : 0;

    return (
        <div id="scoreWindow" className="w-full z-10">
            {videoInfo.prehazard ? <div id="pre1" style={{marginLeft: `${marginLeft.toFixed(2)}%`, width: `${((videoInfo.five - videoInfo.prehazard) * (100 / duration)).toFixed(3)}%`}}></div> : ''}
            <div id="five" style={!videoInfo.prehazard ? {marginLeft: `${marginLeft.toFixed(2)}%`, width: `${((videoInfo.four - videoInfo.five) * (100 / duration)).toFixed(3)}%`} : {width: `${((videoInfo.four - videoInfo.five) * (100 / duration)).toFixed(3)}%`}}></div>
            <div id="four" style={{width: `${((videoInfo.three - videoInfo.four) * (100 / duration)).toFixed(3)}%`}}></div>
            <div id="three" style={{width: `${((videoInfo.two - videoInfo.three) * (100 / duration)).toFixed(3)}%`}}></div>
            <div id="two" style={{width: `${((videoInfo.one - videoInfo.two) * (100 / duration)).toFixed(3)}%`}}></div>
            <div id="one" style={{width: `${((videoInfo.endseq - videoInfo.one) * (100 / duration)).toFixed(3)}%`}}></div>
            {videoInfo.nohazards > 1 ? 
              <span>
                {videoInfo.prehazard2 ? <div id="pre2" style={{marginLeft: `${secondMarginLeft}%`, width: `${((videoInfo.ten! - videoInfo.prehazard2!) * (100 / duration)).toFixed(3)}%`}}></div> : null}
                <div id="ten" style={{marginLeft: `${videoInfo.prehazard2 ? 0 : secondMarginLeft}%`, width: `${((videoInfo.nine! - videoInfo.ten!) * (100 / duration)).toFixed(3)}%`}}></div>
                <div id="nine" style={{width: `${((videoInfo.eight! - videoInfo.nine!) * (100 / duration)).toFixed(3)}%`}}></div>
                <div id="eight" style={{width: `${((videoInfo.seven! - videoInfo.eight!) * (100 / duration)).toFixed(3)}%`}}></div>
                <div id="seven" style={{width: `${((videoInfo.six! - videoInfo.seven!) * (100 / duration)).toFixed(3)}%`}}></div>
                <div id="six" style={{width: `${((videoInfo.endseq2! - videoInfo.six!) * (100 / duration)).toFixed(3)}%`}}></div>
              </span>
             : ''
            }
        </div>
    )
}

export default ScoreWindow
