import _ from "lodash";
import {
    NEXT_LP_QUESTION,
    PREV_LP_QUESTION,
    TOGGLE_SOUND,
    ADD_LP_ANSWER,
    REMOVE_LP_ANSWER,
    SET_SECTION,
    SET_SECTION_NUMBER,
    SET_STUDY,
    SKIP_CORRECT
} from "../actions/types";
import { learningProgramTypes } from "../types/learning";

const LP_INITIAL_STATE = {
    currentQuestion: 1,
    currentSection: 0,
    numQuestions: [],
    voiceOver: false,
    skipCorrect: false,
    study: false,
    correct: [],
    incorrect: [],
    answers: [],
    questions: []
};

const LearningProgramReducer = (
    state: learningProgramTypes = LP_INITIAL_STATE,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case SET_SECTION:
            let questions = state.questions;
            let numQuestions = state.numQuestions;
            questions[action.payload.id] = action.payload.section;
            numQuestions[action.payload.id] = action.payload.section.length;
            return {
                ...state,
                currentSection: action.payload.id,
                questions,
                currentQuestion: 1,
                numQuestions
            };
        case SET_SECTION_NUMBER:
            return {
                ...state,
                currentSection: action.payload,
                currentQuestion: 1
            };
        case NEXT_LP_QUESTION:
            if (state.skipCorrect) {
                let incomplete = _.pullAll(
                    Array.from(
                        state.questions[state.currentSection],
                        (value, index) => index + 1
                    ),
                    state.correct[state.currentSection]
                );
                let incompleteIndex = _.indexOf(
                    incomplete,
                    state.currentQuestion
                );
                if (incompleteIndex < 0) {
                    incompleteIndex = _.findIndex(
                        incomplete,
                        (question) => question <= state.currentQuestion
                    );
                }
                return incompleteIndex < incomplete.length - 1
                    ? {
                          ...state,
                          currentQuestion: incomplete[incompleteIndex + 1]
                      }
                    : { ...state, currentQuestion: incomplete[0] };
            }
            return state.currentQuestion <
                state.numQuestions[state.currentSection]
                ? { ...state, currentQuestion: state.currentQuestion + 1 }
                : { ...state, currentQuestion: 1 };
        case PREV_LP_QUESTION:
            if (state.skipCorrect) {
                let incomplete = _.pullAll(
                    Array.from(
                        state.questions[state.currentSection],
                        (value, index) => index + 1
                    ),
                    state.correct[state.currentSection]
                );
                let incompleteIndex = _.indexOf(
                    incomplete,
                    state.currentQuestion
                );
                if (incompleteIndex < 0) {
                    incompleteIndex = _.findIndex(
                        incomplete,
                        (question) => question >= state.currentQuestion
                    );
                }
                return incompleteIndex > 0
                    ? {
                          ...state,
                          currentQuestion: incomplete[incompleteIndex - 1]
                      }
                    : {
                          ...state,
                          currentQuestion: incomplete[incomplete.length - 1]
                      };
            }
            return state.currentQuestion > 1
                ? { ...state, currentQuestion: state.currentQuestion - 1 }
                : {
                      ...state,
                      currentQuestion: state.numQuestions[state.currentSection]
                  };
        case SET_STUDY:
            return { ...state, study: !state.study };
        case SKIP_CORRECT:
            return { ...state, skipCorrect: !state.skipCorrect };
        case TOGGLE_SOUND:
            return { ...state, voiceOver: !state.voiceOver };
        case ADD_LP_ANSWER:
            let answers = [...state.answers];
            let correct = [...state.correct];
            let incorrect = [...state.incorrect];
            if (!Array.isArray(answers[state.currentSection])) {
                answers[state.currentSection] = [];
            }
            if (!Array.isArray(correct[state.currentSection])) {
                correct[state.currentSection] = [];
            }
            if (!Array.isArray(incorrect[state.currentSection])) {
                incorrect[state.currentSection] = [];
            }
            answers[state.currentSection][state.currentQuestion - 1] =
                action.payload.answer;
            if (
                action.payload.correct ===
                answers[state.currentSection][state.currentQuestion - 1]
            ) {
                correct[state.currentSection].push(state.currentQuestion);
                correct[state.currentSection] = _.uniq(
                    correct[state.currentSection]
                );
                _.pull(incorrect[state.currentSection], state.currentQuestion);
            } else {
                incorrect[state.currentSection].push(state.currentQuestion);
                incorrect[state.currentSection] = _.uniq(
                    incorrect[state.currentSection]
                );
                _.pull(correct[state.currentSection], state.currentQuestion);
            }
            return {
                ...state,
                answers,
                correct,
                incorrect
            };
        case REMOVE_LP_ANSWER:
            let currentAnswers = [...state.answers];
            let currentCorrect = [...state.correct];
            let currentIncorrect = [...state.incorrect];

            currentAnswers[state.currentSection][state.currentQuestion - 1] =
                action.payload;
            _.pull(
                currentIncorrect[state.currentSection],
                state.currentQuestion
            );
            _.pull(currentCorrect[state.currentSection], state.currentQuestion);
            return {
                ...state,
                answers: currentAnswers,
                correct: currentCorrect,
                incorrect: currentIncorrect
            };
        default:
            return state;
    }
};

export default LearningProgramReducer;
