import { ChangeEvent, FormEvent, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import Card from "./Card";
import Input from "./Input";
import Button from "./Button";
import myLDC from '../api/myldc';

export default function Login(props: {onSuccess?: Function}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        myLDC
            .post("/authenticate", {
                username: username,
                password: password
            })
            .then((response: AxiosResponse<any>) => {
                if(response.data === false || response.status !== 200){
                    setError(true);
                }
                else{
                    setError(false)
                    localStorage.setItem('token', response.data);
                    if(props.onSuccess) { props.onSuccess() }
                }
            })
            .catch((error: AxiosError) => {
                console.log(error)
            });
    };

    return (
        <Card>
            <form onSubmit={handleSubmit}>
                {error === true ? <div className="bg-red-200 border-red-600 text-red-600 border-l-4 p-4 py-3" role="alert">
                    <p className="font-bold">
                        Login Details Incorrect
                    </p>
                    <p>
                        Please check your username and password are correct and try again!
                    </p>
                </div> : null}
                <Input
                    autoFocus
                    type="text"
                    value={username}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                    placeholder="Email address"
                />
                <Input
                    type="password"
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <Button
                    type="submit"
                    className="bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200"
                >
                    Login
                </Button>
            </form>
        </Card>
    );
}
