
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExpandArrowsAlt, faCompress} from "@fortawesome/free-solid-svg-icons";

export default function FullscreenButton({handle}: {handle: any}) {
    // @ts-ignore
    const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;

    if(fullScreenAvailable) {
        if(handle.active !== true){
            return <button onClick={handle.enter}><FontAwesomeIcon icon={faExpandArrowsAlt} /></button>
        } else {
            return <button onClick={handle.exit}><FontAwesomeIcon icon={faCompress} /></button>
        }
    }
    return null;
}