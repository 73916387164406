import { useEffect } from 'react';
import { Videos } from './hazard'
import _ from 'lodash'

interface ReviewProps{
    videos: Videos[],
    scores: number[],
    tamper: string[],
    clicks: number[][],
    passMark: number,
    goToVideo: Function,
    testNo: number;
}

function Review({videos, scores, tamper, clicks, passMark, goToVideo, testNo}: ReviewProps) {
    const availableScores = [0, 1, 2, 3, 4, 5];
    const totalScore = scores.length ? scores.reduce((a, b) => a + b) : 0
    const averageScore = scores.length ? (totalScore / (videos.length + 1)).toFixed(1) : 0;
    const backgroundColor = (score: number) => {
        if(score === 5){return 'five'}
        else if(score === 4){return 'four'}
        else if(score === 3){return 'three'}
        else if(score === 2){return 'two'}
        else if(score === 1){return 'one'}
        else{return 'bg-white'}
    }

    useEffect(() => {

    }, []);

    return (
        <div>
            {totalScore >= passMark ? <h2 className="text-3xl font-bold text-center text-green-700">Congratulations you've passed!</h2> : <h2 className="text-3xl font-bold text-center text-red-700">Sorry, you have failed this time!</h2>}
            <h2 className="font-bold text-center my-2 text-lg">You have scored {totalScore ? totalScore : '0'} points out of a possible {(videos.length + 1) * 5}, the pass mark is {passMark}</h2>
            <p className="text-center">To replay and review any of the following clips in more detail please select the appropriate item in the menu list below:</p>
            <table className="table-auto w-full border">
                <thead>
                    <tr className="font-bold bg-gray-300">
                        <th className="p-1.5"></th>
                        <th className="p-1.5">Description</th>
                        <th className="p-1.5">Score</th>
                        <th className="p-1.5">Status</th>
                    </tr>
                </thead>
                <tbody>
                {videos.map((video: Videos, index: number) => {
                    return <tr key={index} className={`${backgroundColor(scores[index])} text-black hover:bg-blue-500 cursor-pointer border border-l-0 border-r-0`} onClick={() => goToVideo(index + 1)}>
                        <td className="text-center p-1.5">{index + 1}</td>
                        <td className="p-1.5">{video.title}{video.nohazards > 1 ? ` & ${video.title2}` : ''}</td>
                        <td className="text-center p-1.5">{scores[index] >= 1 ? scores[index] : '0'}{video.nohazards > 1 ? ` + ${scores[videos.length + 1] ? scores[videos.length + 1] : '0'}` : ''}</td>
                        <td className="text-center p-1.5">{tamper[index] ? tamper[index] : ''}</td>
                    </tr>
                })}
                </tbody>
            </table>
            <div className="grid grid-cols-4 gap-4 mt-3">
                <div className="clipAnalysis text-center col-span-4 mx-3 md:mx-0 md:col-start-2 md:col-span-2">
                    <p>Analysis of all clips by score</p>
                    <div id="score-analysis">
                        <div id="car" style={{left:`${+averageScore / 5 * 100}%`}} className={averageScore < 1.5 ? 'low' : ''}><span>{averageScore} per clip</span></div>
                        {availableScores.map((score, index) => {
                            return <div key={index} id={`score-${score}`} className={`${backgroundColor(score)} text-black`}>
                                {score === 0
                                ? (_.compact(scores).filter(x => x === score).length + ((videos.length + 1) - _.compact(scores).length))
                                : scores.filter(x => x === score).length}
                            </div>
                        })}
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Review
