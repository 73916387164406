// Theory Test
export const SET_TEST_NO = 'SET_TEST_NO'
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const PREV_QUESTION = 'PREV_QUESTION';
export const GO_TO_QUESTION = 'GO_TO_QUESTION';
export const FLAG_QUESTION = 'FLAG_QUESTION';
export const TOGGLE_SOUND = 'TOGGLE_SOUND';
export const ADD_ANSWER = 'ADD_ANSWER';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';
export const REVIEW_ALL = 'REVIEW_ALL';
export const REVIEW_FLAGGED = 'REVIEW_FLAGGED';
export const REVIEW_INCOMPLETE = 'REVIEW_INCOMPLETE';
export const REVIEW_ANSWERS = 'REVIEW_ANSWERS';
export const REVIEW_SUMMARY = 'REVIEW_SUMMARY';
export const REVIEW_TEST = 'REVIEW_TEST';
export const END_TEST = 'END_TEST';

// Learning Program
export const SET_SECTION = 'SET_SECTION';
export const SET_SECTION_NUMBER = 'SET_SECTION_NUMBER';
export const SKIP_CORRECT = 'SKIP_CORRECT';
export const SET_STUDY = 'SET_STUDY';
export const ADD_LP_ANSWER = 'ADD_LP_ANSWER';
export const REMOVE_LP_ANSWER = 'REMOVE_LP_ANSWER';
export const NEXT_LP_QUESTION = 'NEXT_LP_QUESTION';
export const PREV_LP_QUESTION = 'PREV_LP_QUESTION';

// Hazard Perception
export const SET_HP_TEST = 'SET_HP_TEST';
export const SET_VIDEOS = 'SET_VIDEOS';
export const NEXT_VIDEO = 'NEXT_VIDEO';
export const PREV_VIDEO = 'PREV_VIDEO';
export const GO_TO_VIDEO = 'GO_TO_VIDEO';
export const ADD_CLICK_TIME = 'ADD_CLICK_TIME';
export const EXIT_HP_TEST = 'EXIT_HP_TEST';
export const PLAY_VIDEO = 'PLAY_VIDEO';
export const PAUSE_VIDEO = 'PAUSE_VIDEO';
export const PLAY_PAUSE_VIDEO = 'PLAY_PAUSE_VIDEO';
export const PLAYBACK_RATE = 'PLAYBACK_RATE';
export const SET_DURATION = 'SET_DURATION';
export const SET_PLAYED = 'SET_PLAYED';
export const SET_ERROR = 'SET_ERROR';
export const SCORE_PAUSE = 'SCORE_PAUSE';
export const SEEK_CHANGE = 'SEEK_CHANGE';
export const SEEK_START = 'SEEK_START';
export const SEEK_END = 'SEEK_END';
export const BUFFERING = 'BUFFERING';
export const VIDEO_READY = 'VIDEO_READY';
export const VIDEO_ENDED = 'VIDEO_ENDED';
export const RESET_TEST = 'RESET_TEST';
export const REVIEW_HP_TEST = 'REVIEW_HP_TEST';